import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppAdmin from '../appMenu/appAdmin';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import Ci from './ci';
import CiCliente from './ciCliente';


function Controle(){
    const token = Cookies.get('ANers');
    const {id} = useParams();
    const [authenticated, setAuthenticated] = useState(false);
    const [response, setResponse] = useState(false);
    const [responseToken, setResponseToken] = useState(false);
    const [respUser, setRespUser] = useState(false);
    
    useEffect(() => {
      axios.post('/api/getprocess', {token}, {
        withCredentials: true,
      })
        .then((response) => {
          if (response.data.auth === true) {
            setAuthenticated(true);
            console.log(response.data.resp[0])
            console.log('===='+authenticated)
            setResponse(response.data)
            setResponseToken(response.data.nivel)
	    setRespUser(response.data.respUser)
          } else {
            window.location = '/login';
          }
        })
        .catch(() => {
          window.location = '/login';
        });
    }, [authenticated]);
    
  
  
    if (!authenticated) {
      return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>;
    }
  
   if (responseToken == "Aners") {
        return (
            <Ci response={response} />
        );
    } else {
        return (
            <CiCliente response={response} />
        );
    }
      
  }

 export default Controle
