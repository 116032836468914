import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import PieChartIcon from '@mui/icons-material/PieChart';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import TableChartIcon from '@mui/icons-material/TableChart';
import Groups2Icon from '@mui/icons-material/Groups2';
import HelpIcon from '@mui/icons-material/Help';


export default function AppAdmin({ open, setOpen }) {

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(isOpen);
  };

  const menuItems = [
    { text: 'Início', icon: <HomeIcon />, link: '/' },
    { text: 'Comprovantes', icon: <DescriptionIcon />, link: '/comprovantes' },
    { text: 'Relatórios', icon: <PieChartIcon />, link: '/relatorios' },
    { text: 'Suporte', icon: <HelpIcon />, link: '/suporte' },
    { text: 'Cadastrar Usuário', icon: <PersonAddIcon />, link: '/cadastrar/usuario' },
    { text: 'Consultar Usuários', icon: <GroupIcon />, link: '/consultar/usuarios' },
    { text: 'Consultar Clientes', icon: <TableChartIcon />, link: '/consultar/clientes' },
    { text: 'Consultar Pessoas', icon: <Groups2Icon />, link: '/consultar/pessoas' },
  ];

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleDrawer(false)}
      PaperProps={{
        sx: { width: 240, backgroundColor: '#17a2b8', color: 'white' }
      }}
    >
      <div
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        style={{ width: 240 }}
      >
        <div style={{ textAlign: 'center', padding: '16px' }}>
          <img
            src="/dist/img/pngBranco.png"
            alt="Admin Logo"
            style={{ width: 80, borderRadius: '50%' }}
          />
          <div style={{ color: 'white', fontWeight: 'bold', marginTop: 8 }}>
            AN Documentos
          </div>
        </div>

        <Divider />

        <List>
          {menuItems.map((item) => (
            <ListItem button component={Link} to={item.link} key={item.text}>
              <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} sx={{ color: 'white' }} />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
}
