import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Snackbar,
    Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

function Pessoas() {
    const navigate = useNavigate();
    const token = Cookies.get("ANers");
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [people, setPeople] = useState([]);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    // Estados para o Modal de Edição
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editFormData, setEditFormData] = useState({
        nome: "",
        cpf_cnpj: "",
        telefone: "",
    });
    const [formErrors, setFormErrors] = useState({
        nome: "",
        cpf_cnpj: "",
        telefone: "",
    });

    // Estados para o Modal de Criação
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [createFormData, setCreateFormData] = useState({
        nome: "",
        cpf_cnpj: "",
        telefone: "",
    });
    const [createFormErrors, setCreateFormErrors] = useState({
        nome: "",
        cpf_cnpj: "",
        telefone: "",
    });

    // Estados para o Snackbar de Feedback
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success", // 'error', 'warning', 'info'
    });

    // Função para formatar o campo de CPF/CNPJ
    function formatCpf_Cnpj(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito

        let novoValor = "";

        if (valor.length <= 11) {
            // CPF
            novoValor = valor.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4"
            );
        } else {
            // CNPJ
            novoValor = valor.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
                "$1.$2.$3/$4-$5"
            );
        }

        campo.value = novoValor;
    }

    // Função para formatar os campos de telefone
    function formatTelefone(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito

        let novoValor = "";

        if (valor.length <= 10) {
            novoValor = valor.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
        } else {
            novoValor = valor.replace(/(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
        }

        campo.value = novoValor;
    }

    useEffect(() => {
        axios
            .get(`/api/get/pessoa` , {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    console.log(response.data);
                    setUser(response.data.user);
                    setPeople(response.data.pessoas);
                    setAuthenticated(true);
                    setLoading(false);
                }
            })
            .catch(() => {
                navigate("/login");
            });
    }, [navigate, token]);

    if (!authenticated) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    // Função para remover uma pessoa
    async function removePerson(id) {
        try {
            const response = await axios.delete(
                `/api/remove/pessoa/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
            })
            if (response.data.status === 200) {
                setPeople(people.filter((person) => person.id !== id));
                setOpenDeleteDialog(false);
                handleSnackbarOpen(response.data.msg, "success");
            } else {
                handleSnackbarOpen(response.data.msg, "error");
            }
        } catch (error) {
            console.log(error);
            handleSnackbarOpen("Ocorreu um erro ao remover a pessoa.", "error");
        }
    }

    // Função para abrir o modal de edição
    const handleEditClick = (person) => {
        setSelectedPersonId(person.id);
        setEditFormData({
            nome: person.nome,
            cpf_cnpj: person.cpf_cnpj,
            telefone: person.telefone,
        });
        setFormErrors({
            nome: "",
            cpf_cnpj: "",
            telefone: "",
        });
        setOpenEditDialog(true);
    };

    // Função para lidar com mudanças nos campos do formulário de edição
    const handleEditFormChange = (event) => {
        const { name, value } = event.target;
        setEditFormData({
            ...editFormData,
            [name]: value,
        });
    };

    // Função para validar o formulário de edição
    const validateEditForm = () => {
        let errors = {};
        if (!editFormData.nome.trim()) {
            errors.nome = "O nome é obrigatório.";
        }
        if (!editFormData.cpf_cnpj.trim()) {
            errors.cpf_cnpj = "O CPF/CNPJ é obrigatório.";
        } else if (!/^\d{11}(\d{2})?$/.test(editFormData.cpf_cnpj)) {
            errors.cpf_cnpj = "CPF/CNPJ inválido.";
        }
        if (!editFormData.telefone.trim()) {
            errors.telefone = "O telefone é obrigatório.";
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    // Função para salvar as alterações
    const handleSaveEdit = async () => {
        if (!validateEditForm()) {
            return;
        }

        try {
            const response = await axios.put(
                `/api/update/pessoa/${selectedPersonId}`,
                editFormData
                , {
                    headers: { Authorization: `Bearer ${token}` },
                    withCredentials: true,
                })

            if (response.data.status === 200) {
                setPeople(
                    people.map((person) =>
                        person.id === selectedPersonId
                            ? { ...person, ...editFormData }
                            : person
                    )
                );
                setOpenEditDialog(false);
                handleSnackbarOpen("Alterações feitas com sucesso!", "success");
            } else {
                handleSnackbarOpen(response.data.msg, "error");
            }
        } catch (error) {
            console.log(error);
            handleSnackbarOpen(
                "Ocorreu um erro ao atualizar a pessoa.",
                "error"
            );
        }
    };

    // Função para abrir o modal de criação
    const handleCreateClick = () => {
        setCreateFormData({
            nome: "",
            cpf_cnpj: "",
            telefone: "",
        });
        setCreateFormErrors({
            nome: "",
            cpf_cnpj: "",
            telefone: "",
        });
        setOpenCreateDialog(true);
    };

    // Função para lidar com mudanças nos campos do formulário de criação
    const handleCreateFormChange = (event) => {
        const { name, value } = event.target;
        setCreateFormData({
            ...createFormData,
            [name]: value,
        });
    };

    // Função para validar o formulário de criação
    const validateCreateForm = () => {
        let errors = {};
        if (!createFormData.nome.trim()) {
            errors.nome = "O nome é obrigatório.";
        }
        if (!createFormData.cpf_cnpj.trim()) {
            errors.cpf_cnpj = "O CPF/CNPJ é obrigatório.";
        }

        if (!createFormData.telefone.trim()) {
            errors.telefone = "O telefone é obrigatório.";
        }

        setCreateFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    // Função para criar uma nova pessoa
    const handleSaveCreate = async () => {
        if (!validateCreateForm()) {
            return;
        }

        try {
            const response = await axios.post(
                `/api/new/pessoa`,
                createFormData, {
                    headers: { Authorization: `Bearer ${token}` },
                    withCredentials: true,
                })

            if (response.data.status === 200) {
                // Supondo que o status de criação bem-sucedida seja 201
                setPeople([...people, response.data.pessoa]); // Supondo que a nova pessoa retornada esteja em response.data.pessoa
                setOpenCreateDialog(false);
                handleSnackbarOpen("Pessoa criada com sucesso!", "success");
            } else {
                handleSnackbarOpen(response.data.msg, "error");
            }
        } catch (error) {
            console.log(error);
            handleSnackbarOpen("Ocorreu um erro ao criar a pessoa.", "error");
        }
    };

    // Função para abrir o Snackbar
    const handleSnackbarOpen = (message, severity = "success") => {
        setSnackbar({
            open: true,
            message,
            severity,
        });
    };

    // Função para fechar o Snackbar
    const handleSnackbarClose = () => {
        setSnackbar({
            ...snackbar,
            open: false,
        });
    };

return (
        <>
            <Box sx={{ marginTop: 10, paddingX: 2 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Consultar Pessoas
                </Typography>
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Button
                        variant="contained"
                        sx={{bgcolor: '#14a2b8'}}
                        startIcon={<AddIcon />}
                        onClick={handleCreateClick}
                    >
                        Adicionar Pessoa
                    </Button>
                </Box>
                <Paper>
                    <TableContainer component={Paper}>
                        <Table aria-label="Tabela de Pessoas">
                            <TableHead>
                                <TableRow sx={{ bgcolor: "#14a2b8" }}>
                                    <TableCell sx={{ color: "#fff" }}>
                                        #
                                    </TableCell>
                                    <TableCell sx={{ color: "#fff" }}>
                                        Nome
                                    </TableCell>
                                    <TableCell sx={{ color: "#fff" }}>
                                        CPF/CNPJ
                                    </TableCell>
                                    <TableCell sx={{ color: "#fff" }}>
                                        Telefone
                                    </TableCell>
                                    <TableCell
                                        sx={{ color: "#fff" }}
                                        align="center"
                                    >
                                        Ações
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {people &&
                                    people.map((person, index) => (
                                        <TableRow key={person.id}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{person.nome}</TableCell>
                                            <TableCell>
                                                {person.cpf_cnpj}
                                            </TableCell>
                                            <TableCell>
                                                {person.telefone}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    color="error"
                                                    onClick={() => {
                                                        setSelectedPersonId(
                                                            person.id
                                                        );
                                                        setOpenDeleteDialog(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                        handleEditClick(person)
                                                    }
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>

            {/* Diálogo de confirmação para exclusão */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>Deseja mesmo excluir esta pessoa?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta ação não pode ser desfeita.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDeleteDialog(false)}
                        color="primary"
                    >
                        Não
                    </Button>
                    <Button
                        onClick={() => removePerson(selectedPersonId)}
                        color="error"
                        autoFocus
                    >
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo para Edição */}
            <Dialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Editar Pessoa</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            marginTop: 1,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            label="Nome"
                            name="nome"
                            value={editFormData.nome}
                            onChange={handleEditFormChange}
                            fullWidth
                            error={!!formErrors.nome}
                            helperText={formErrors.nome}
                        />
                        <TextField
                            label="CPF/CNPJ"
                            name="cpf_cnpj"
                            value={editFormData.cpf_cnpj}
                            onChange={handleEditFormChange}
                            onInput={formatCpf_Cnpj}
                            fullWidth
                            error={!!formErrors.cpf_cnpj}
                            helperText={formErrors.cpf_cnpj}
                        />
                        <TextField
                            label="Telefone"
                            name="telefone"
                            value={editFormData.telefone}
                            onChange={handleEditFormChange}
                            onInput={formatTelefone}
                            fullWidth
                            error={!!formErrors.telefone}
                            helperText={formErrors.telefone}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenEditDialog(false)}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSaveEdit}
                        color="primary"
                        variant="contained"
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo para Criação */}
            <Dialog
                open={openCreateDialog}
                onClose={() => setOpenCreateDialog(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Adicionar Nova Pessoa</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            marginTop: 1,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            label="Nome"
                            name="nome"
                            value={createFormData.nome}
                            onChange={handleCreateFormChange}
                            fullWidth
                            error={!!createFormErrors.nome}
                            helperText={createFormErrors.nome}
                        />
                        <TextField
                            label="CPF/CNPJ"
                            name="cpf_cnpj"
                            value={createFormData.cpf_cnpj}
                            onChange={handleCreateFormChange}
                            onInput={formatCpf_Cnpj}
                            fullWidth
                            error={!!createFormErrors.cpf_cnpj}
                            helperText={createFormErrors.cpf_cnpj}
                        />
                        <TextField
                            label="Telefone"
                            name="telefone"
                            value={createFormData.telefone}
                            onChange={handleCreateFormChange}
                            onInput={formatTelefone}
                            fullWidth
                            error={!!createFormErrors.telefone}
                            helperText={createFormErrors.telefone}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenCreateDialog(false)}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSaveCreate}
                        color="primary"
                        variant="contained"
                    >
                        Adicionar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para Feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbar.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Pessoas;

