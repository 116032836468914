import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import Cliente from './cliente';
import AppAdmin from '../appMenu/appAdmin';
import Aners from './aners';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";


function Editor(){
  const token = Cookies.get('ANers');
  const {id} = useParams();
  const [authenticated, setAuthenticated] = useState(false);
  const [responseToken, setResponseToken] = useState(false);
  const [response, setResponse] = useState(false);
  
  useEffect(() => {
    axios.post(`/api/getoneprocess/${id}`, {token}, {
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.auth === true) {
          setAuthenticated(true);
          setResponseToken(response.data.nivel)
          setResponse(response)
        } else {
          window.location = '/login';
        }
      })
      .catch(() => {
        window.location = '/login';
      });
  }, [authenticated]);
  


  if (!authenticated) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>;
  }

   if (responseToken == "Aners") {
        return (
            <>
                <Aners response={response} />
            </>
        );
    } else {
        return (
            <>
                <Cliente response={response} />
            </>
        );
    }
    
}

export default Editor;
