import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import $ from "jquery";

function Aners({ response }) {
    const token = Cookies.get("ANers");
    const { id } = useParams();
    const [authenticated, setAuthenticated] = useState(false);
    const [responseProcess, setResponse] = useState(false);
    const [responseUsuario, setResponseUsuario] = useState(false);
    const [responseReu, setResponseReu] = useState(false);
    const [responseAutor, setResponseAutor] = useState(false);
    const [responseAdvogado, setResponseAdvogado] = useState(false);
    const [responsePagamento, setResponsePagamento] = useState(false);
    const [responseWorkflow, setResponseWorkflow] = useState(false);
    const [responseAcao, setResponseAcao] = useState(false);
    const [responseAnexo, setResponseAnexo] = useState(false);
    const [responseDocs, setResponseDocs] = useState(false);
    const [responsePessoa, setResponsePessoa] = useState(false);
    const [responseTimeLine, setResponseTimeLine] = useState(false);
    const [responseParam, setResponseParam] = useState(false);
    const [responseForum, setResponseForum] = useState(false);
    const [valueCpf, setValueCpf] = useState("");
    const [valueCel, setValueCel] = useState("");
    const [selectedPayment, setSelectedPayment] = useState({
        tipo: null,
        descricao: null,
        valor: null,
        data_pagamento: null,
        anexo: null,
        nome: null,
        cpf: null,
        agencia: null,
        conta: null,
        banco: null,
        digito: null,
    });
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        if (response.data.auth === true) {
            setAuthenticated(true);
            setResponse(response.data.resp);
            setResponseUsuario(response.data.usuario);
            setResponseReu(response.data.reus);
            setResponseAutor(response.data.autores);
            setResponseAdvogado(response.data.advogados);
            setResponsePagamento(response.data.pagamentos);
            setResponseWorkflow(response.data.workflows);
            setResponseAnexo(response.data.anexos);
            setResponseDocs(response.data.docs);
            setResponsePessoa(response.data.pessoas);
            setResponseParam(response.data.param);
            setResponseAcao(response.data.acao);
            setResponseTimeLine(response.data.timeline);
            setResponseForum(response.data.forum);
        } else {
            window.location = "/login";
        }
    }, [authenticated]);

    const [value, setValue] = useState("");

    function handleInputChange(event) {
        const { value, name } = event.target;

        const newValue = value
            .replace(/\D/g, "") // Remove tudo que não for número
            .replace(/^0+/, "") // Remove os zeros iniciais
            .padStart(3, "0") // Adiciona zeros à esquerda, caso necessário
            .replace(/(\d{1,})(\d{2})$/, "$1,$2") // Adiciona a vírgula decimal
            .replace(/(?=(\d{3})+(\D))\B/g, "."); // Adiciona o ponto como separador de milhares

        setValue(`R$ ${newValue}`);
    }

    if (!authenticated) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
    function atualizarTitulo(titulo) {
        if (titulo < 1 || titulo > 8) {
            return;
        }
        switch (titulo) {
            case 1:
                document.querySelector("#titulo").textContent =
                    "Dados da Requisição";
                break;
            case 2:
                document.querySelector("#titulo").textContent =
                    "Anexos Adicionados";
                break;
            case 3:
                document.querySelector("#titulo").textContent = "Réu";
                break;
            case 4:
                document.querySelector("#titulo").textContent = "Autores";
                break;
            case 5:
                document.querySelector("#titulo").textContent = "Advogados";
                break;
            case 6:
                document.querySelector("#titulo").textContent = "Pagamentos";
                break;
            case 7:
                document.querySelector("#titulo").textContent = "Status";
                break;
            case 8:
                document.querySelector("#titulo").textContent = "Documentos";
                break;
            default:
                console.log("Erro!");
        }
    }
    function deleteAdv(n) {
        axios
            .post(
                `/api/remove/attorney/${n}`,
                { token, responseParam },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                window.location = response.data;
            });
    }

    function deleteReu(n) {
        axios
            .post(
                `/api/remove/defendant/${n}`,
                { token, responseParam },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                window.location = response.data;
            });
    }

    function deleteActor(n) {
        axios
            .post(
                `/api/remove/actor/${n}`,
                { token, responseParam },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                window.location = response.data;
            });
    }

    function deleteAnexo(n) {
        axios
            .post(
                `/api/remove/attachment/${n}`,
                { token, responseParam },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                window.location = response.data;
            });
    }

    function deletePagamento(n) {
        axios
            .post(
                `/api/remove/payment/${n}`,
                { token, responseParam },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                window.location = response.data;
            });
    }

    let fileList = new DataTransfer();

    function removeAttachment(i) {
        const upload = document.querySelector("#upload");
        const divUpload = document.querySelector("#novosAnexos");
        const attachments = divUpload.querySelectorAll(".row"); // obtém todos os elementos .row dentro do divUp1

        let files = upload.files;
        const dt = new DataTransfer();
        for (let c = 0; c < files.length; c++) {
            console.log("++++++++++", files[c]);
            if (c !== i) {
                dt.items.add(files[c]);
            }
        }

        const newFiles = dt.files;
        console.log(newFiles[0]);
        upload.files = newFiles;
        console.log(upload.files[0]);
        // Remove o elemento no índice i da lista de anexos
        console.log("Antes de remover: " + i);
        fileList.items.remove(i);
        attachments[i].remove();
        const newDivUpload = document.querySelector("#novosAnexos");
        const icon = newDivUpload.querySelectorAll("i");
        const attach = newDivUpload.querySelectorAll(".row");
        console.log("len: " + icon.length);
        for (let cont = 0; cont < icon.length; cont++) {
            console.log(cont);
            icon[cont].onclick = () => removeAttachment(cont);
        }
    }

    const handleFileUpload = (event) => {
        let file = event.target.files;

        for (let i = 0; i < file.length; i++) {
            fileList.items.add(file[i]);
            const divUpload = document.querySelector("#divUp1");
            //const newAtt = document.querySelector('#newAttach');
            const novos = document.querySelector("#novosAnexos");
            const rowDiv = document.createElement("div");
            rowDiv.className = "row";

            const col7Div = document.createElement("div");
            col7Div.className = "col-12";

            const label = document.createElement("label");
            label.textContent = `${file[i].name}`;
            const spanUpload = document.createElement("span");
            spanUpload.className = "hidden-xs";
            const iconUp = document.createElement("i");
            iconUp.className =
                "fa fa-minus-circle ml-3 btn-remove-anexo text-danger";
            iconUp.style.fontSize = "1.25em";
            iconUp.style.position = "relative";
            iconUp.style.top = ".15em";
            iconUp.onclick = () => removeAttachment(i);
            spanUpload.appendChild(iconUp);
            col7Div.appendChild(label);
            col7Div.appendChild(spanUpload);
            rowDiv.appendChild(col7Div);

            //divUpload.insertBefore(rowDiv, newAtt)
            novos.appendChild(rowDiv);
        }

        event.target.files = fileList.files;
        console.log("Files: " + file);

        const newDivUpload = document.querySelector("#novosAnexos");
        const icon = newDivUpload.querySelectorAll("i");
        for (let i = 0; i < icon.length; i++) {
            icon[i].onclick = () => removeAttachment(i);
        }
    };

    function removeDocs(i) {
        const upload = document.querySelector("#drop-file");
        const divUpload = document.querySelector("#novoDocumento");
        const attachments = divUpload.querySelectorAll(".row"); // obtém todos os elementos .row dentro do divUp1

        let files = upload.files;
        const dt = new DataTransfer();
        for (let c = 0; c < files.length; c++) {
            console.log("++++++++++", files[c]);
            if (c !== i) {
                dt.items.add(files[c]);
            }
        }

        const newFiles = dt.files;
        console.log(newFiles[0]);
        upload.files = newFiles;
        console.log(upload.files[0]);
        // Remove o elemento no índice i da lista de anexos
        console.log("Antes de remover: " + i);
        console.log(attachments);
        fileList.items.remove(i);
        attachments[i].remove();
        const newDivUpload = document.querySelector("#novoDocumento");
        const icon = newDivUpload.querySelectorAll("i");
        const attach = newDivUpload.querySelectorAll(".row");
        console.log("len: " + icon.length);
        for (let cont = 0; cont < icon.length; cont++) {
            console.log(cont);
            icon[cont].onclick = () => removeDocs(cont);
        }
    }

    const handleDocsUpload = (event) => {
        let file = event.target.files;

        for (let i = 0; i < file.length; i++) {
            fileList.items.add(file[i]);
            const divUpload = document.querySelector("#arquivosAdd");
            //const newAtt = document.querySelector('#newAttach');
            const novos = document.querySelector("#novoDocumento");
            const rowDiv = document.createElement("div");
            rowDiv.className = "row";

            const col7Div = document.createElement("div");
            col7Div.className = "col-12";

            const label = document.createElement("label");
            label.textContent = `${file[i].name}`;
            const spanUpload = document.createElement("span");
            spanUpload.className = "hidden-xs";
            const iconUp = document.createElement("i");
            iconUp.className =
                "fa fa-minus-circle ml-3 btn-remove-anexo text-danger";
            iconUp.style.fontSize = "1.25em";
            iconUp.style.position = "relative";
            iconUp.style.top = ".15em";
            iconUp.onclick = () => removeDocs(i);
            spanUpload.appendChild(iconUp);
            col7Div.appendChild(label);
            col7Div.appendChild(spanUpload);
            rowDiv.appendChild(col7Div);

            //divUpload.insertBefore(rowDiv, newAtt)
            novos.appendChild(rowDiv);
        }

        event.target.files = fileList.files;
        console.log("Files: " + file);

        const newDivUpload = document.querySelector("#novoDocumento");
        const icon = newDivUpload.querySelectorAll("i");
        for (let i = 0; i < icon.length; i++) {
            icon[i].onclick = () => removeDocs(i);
        }
    };

    const removeAttachmentPayment = (event) => {
        const inputFile = document.querySelector("#upload2");
        const attachments = document.querySelector("#arquivosAdd"); // obtém todos os elementos .row dentro do divUp1
        if (inputFile) {
            inputFile.value = "";
            attachments.remove();
        }
    };

    const selectedAttachment = (event) => {
        let file = event.target.files;
        const verifyFile = document.querySelector("#arquivosAdd");
        if (verifyFile) {
            verifyFile.remove();
        }
        const novos = document.querySelector("#novoAnexo");
        const rowDiv = document.createElement("div");
        rowDiv.className = "row";
        rowDiv.id = "arquivosAdd";

        const col7Div = document.createElement("div");
        col7Div.className = "col-12";

        const label = document.createElement("label");
        label.textContent = `${file[0].name}`;
        const spanUpload = document.createElement("span");
        spanUpload.className = "hidden-xs";
        const iconUp = document.createElement("i");
        iconUp.className =
            "fa fa-minus-circle ml-3 btn-remove-anexo text-danger";
        iconUp.style.fontSize = "1.25em";
        iconUp.style.position = "relative";
        iconUp.style.top = ".15em";
        iconUp.onclick = removeAttachmentPayment;
        spanUpload.appendChild(iconUp);
        col7Div.appendChild(label);
        col7Div.appendChild(spanUpload);
        rowDiv.appendChild(col7Div);

        //divUpload.insertBefore(rowDiv, newAtt)
        novos.appendChild(rowDiv);
    };

    const removeAttachmentPaymentEdit = (event) => {
        const inputFile = document.querySelector("#upload3");
        const attachments = document.querySelector("#arquivosAddEdit"); // obtém todos os elementos .row dentro do divUp1
        if (inputFile) {
            inputFile.value = "";
            attachments.remove();
        }
    };

    const selectedAttachmentEditar = (event) => {
        let file = event.target.files;
        const verifyFile = document.querySelector("#arquivosAddEdit");
        if (verifyFile) {
            verifyFile.remove();
        }
        const novos = document.querySelector("#novoAnexoEditar");
        const rowDiv = document.createElement("div");
        rowDiv.className = "row";
        rowDiv.id = "arquivosAddEdit";

        const col7Div = document.createElement("div");
        col7Div.className = "col-12";

        const label = document.createElement("label");
        label.textContent = `${file[0].name}`;
        const spanUpload = document.createElement("span");
        spanUpload.className = "hidden-xs";
        const iconUp = document.createElement("i");
        iconUp.className =
            "fa fa-minus-circle ml-3 btn-remove-anexo text-danger";
        iconUp.style.fontSize = "1.25em";
        iconUp.style.position = "relative";
        iconUp.style.top = ".15em";
        iconUp.onclick = removeAttachmentPaymentEdit;
        spanUpload.appendChild(iconUp);
        col7Div.appendChild(label);
        col7Div.appendChild(spanUpload);
        rowDiv.appendChild(col7Div);

        //divUpload.insertBefore(rowDiv, newAtt)
        novos.appendChild(rowDiv);
    };

    function clickUpload(event) {
        const inputFile = document.getElementById("upload");
        inputFile.click();
    }

    function clickUpload2() {
        const inputFile = document.getElementById("upload2");
        inputFile.click();
    }

    function testando() {
            return (
                <div className="row">
                    <div
                        className="col-xl-3 col-lg-4 col-md-4 div-button-success text-white"
                        title="Gerar Novo Processo"
                        data-toggle="modal"
                        data-target="#exampleModal"
                    >
                        <i className="fas fa-plus"></i>Gerar Novo Pagamento
                    </div>
                </div>
            );
    }

    function tipoPagamento() {
        let tipo = document.querySelector("#tipo");

        if (tipo.value == "Acordo") {
            let acordo = (document.querySelector("#classN").style.display =
                "block");
            let anexo = (document.querySelector(
                "#pagamentoAnexo"
            ).style.display = "none");
            let upload2 = (document.querySelector("#upload2").value = "");
        } else {
            let acordo = (document.querySelector("#classN").style.display =
                "none");
            let anexo = (document.querySelector(
                "#pagamentoAnexo"
            ).style.display = "block");
        }
    }

    function tipoPagamentoEdit() {
        let tipo = document.querySelector("#tipoEdit");
        console.log("------------> ");
        if (tipo.value == "Acordo") {
            console.log("Dentro");
            let acordo = (document.querySelector("#classEdit").style.display =
                "block");
            //let anexo = document.querySelector('#pagamentoAnexoEdit').style.display = "none"
            //let upload3 = document.querySelector('#upload3').value= ""
        } else {
            let acordo = (document.querySelector("#classEdit").style.display =
                "none");
        }
    }

    function money() {
        let total = 0;
        responsePagamento.map((resp) => {
            let currency = resp.valor.replace(/\D/g, "");
            let curr = parseFloat(currency) / 100;
            total = total + curr;
        });

        let totalF = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(total);

        return totalF;
    }

    function informacao(pagamento) {
        console.log(`Pagamentos: ${pagamento}`);
        if (pagamento.nome) {
            return (
                <th>
                    Nome: {pagamento.nome}
                    <br />
                    CPF/CNPJ: {pagamento.cpf}
                    <br />
                    AGENCIA: {pagamento.agencia} <br />
                    CONTA: {pagamento.conta} <br />
                    BANCO: {pagamento.banco}
                </th>
            );
        } else {
            return <th></th>;
        }
    }

    function edit(n) {
        axios
            .post(
                `/api/get/payment/${n}`,
                { token },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                setSelectedPayment(response.data.pay);
                document.querySelector("#anexoDiv").style.display = "block";
                document
                    .querySelector("#anexoEdit")
                    .removeAttribute("disabled");
                if (selectedPayment.tipo == "Acordo") {
                    document.querySelector("#nomes").style.backgroundColor =
                        "white";
                    document.querySelector("#banco").style.backgroundColor =
                        "white";
                    document.querySelector("#cpf").style.backgroundColor =
                        "white";
                    document.querySelector("#agencia").style.backgroundColor =
                        "white";
                    document.querySelector("#conta").style.backgroundColor =
                        "white";
                    document.querySelector("#digito").style.backgroundColor =
                        "white";
                    document.querySelector(
                        "#data_pagamento"
                    ).style.backgroundColor = "white";
                    document.querySelector("#valor").style.backgroundColor =
                        "white";
                }
            });
    }

    function verifyConfirmWorkflow() {
        console.log(responseWorkflow);
        if (responseWorkflow) {
            console.log("Response");
            return null;
        } else {
            console.log("Sem response");
            return (
                <div
                    className="col-12 confirm-success text-white"
                    id="envio-pagamentos"
                    onClick={confirmWorkflow}
                >
                    <div className="row center-element">Enviar</div>
                </div>
            );
        }
    }

    function confirmWorkflow() {
        console.log("=========================--------------------------");
        axios
            .post(
                `/api/confirm/process/${id}`,
                { token },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                window.location = response.data;
            });
    }
    function updatePagamento() {
        const formData = new FormData();
        if (selectedPayment.tipo == "Acordo") {
            const nomes = document.querySelector("#nomes").value;
            const banco = document.querySelector("#banco").value;
            const cpf = document.querySelector("#cpf").value;
            const agencia = document.querySelector("#agencia").value;
            const conta = document.querySelector("#conta").value;
            const digito = document.querySelector("#digito").value;
            const data_pagamento =
                document.querySelector("#data_pagamento").value;
            const valor = document.querySelector("#valor").value;
            const descricao = document.querySelector("#descricao").value;
            const upload = document.querySelector("#upload3");
            if (upload.files.length > 0) {
                formData.append("upload", upload.files[0]);
            }
            formData.append("token", token);
            formData.append("nomes", nomes);
            formData.append("banco", banco);
            formData.append("cpf", cpf);
            formData.append("agencia", agencia);
            formData.append("descricao", descricao);
            formData.append("conta", conta);
            formData.append("digito", digito);
            formData.append("data_pagamento", data_pagamento);
            formData.append("valor", valor);
            axios
                .post(`/api/update/payment/${selectedPayment.id}`, formData, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    if (resp.data.erros.length === 0) {
                        window.location = resp.data.url;
                    } else {
                        for (let i = 0; i < resp.data.erros.length; i++) {
                            let elemento = document.querySelector(
                                `#${resp.data.erros[i].elemento}`
                            );
                            elemento.style.backgroundColor = "#FFC0CB";
                        }
                    }
                });
        } else {
            const data_pagamento =
                document.querySelector("#data_pagamento").value;
            const valor = document.querySelector("#valor").value;
            const upload = document.querySelector("#upload3");
            const descricao = document.querySelector("#descricao").value;
            //const divErros = document.querySelector('#erros')
            if (upload.files.length > 0) {
                formData.append("upload", upload.files[0]);
            }
            formData.append("token", token);
            formData.append("data_pagamento", data_pagamento);
            formData.append("valor", valor);
            formData.append("descricao", descricao);
            axios
                .post(`/api/update/payment/${selectedPayment.id}`, formData, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    if (resp.data.erros.length === 0) {
                        window.location = resp.data.url;
                    } else {
                        for (let i = 0; i < resp.data.erros.length; i++) {
                            let elemento = document.querySelector(
                                `#${resp.data.erros[i].elemento}`
                            );
                            elemento.style.backgroundColor = "#FFC0CB";
                        }
                    }
                });
        }
    }

    function novoPagamento() {
        const formData = new FormData();
        const tipo = document.querySelector("#tipo").value;
        console.log("------------------" + tipo);
        if (tipo == "Acordo") {
            const nomes = document.querySelector("#nomes_novo").value;
            const banco = document.querySelector("#banco_novo").value;
            const cpf = document.querySelector("#cpf_novo").value;
            const agencia = document.querySelector("#agencia_novo").value;
            const conta = document.querySelector("#conta_novo").value;
            const digito = document.querySelector("#digito_novo").value;
            const data_pagamento = document.querySelector(
                "#data_pagamento_novo"
            ).value;
            const valor = document.querySelector("#valor_novo").value;
            const numeroParcelas = document.querySelector(
                "#numeroParcelas_novo"
            ).value;
            const descricao = document.querySelector("#descricao_novo").value;

            formData.append("token", token);
            formData.append("nomes", nomes);
            formData.append("tipo", tipo);
            formData.append("banco", banco);
            formData.append("cpf", cpf);
            formData.append("agencia", agencia);
            formData.append("conta", conta);
            formData.append("digito", digito);
            formData.append("data_pagamento", data_pagamento);
            formData.append("valor", valor);
            formData.append("numeroParcelas", numeroParcelas);
            formData.append("descricao", descricao);
            axios
                .post(`/api/new/payment/${id}`, formData, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    if (resp.data.erros.length === 0) {
                        window.location = resp.data.url;
                    } else {
                        for (let i = 0; i < resp.data.erros.length; i++) {
                            let elemento = document.querySelector(
                                `#${resp.data.erros[i].elemento}`
                            );
                            console.log(resp.data.erros[i].elemento);
                            elemento.style.backgroundColor = "#FFC0CB";
                        }
                    }
                });
        } else {
            const data_pagamento = document.querySelector(
                "#data_pagamento_novo"
            ).value;
            const valor = document.querySelector("#valor_novo").value;
            const numeroParcelas = document.querySelector(
                "#numeroParcelas_novo"
            ).value;
            const descricao = document.querySelector("#descricao_novo").value;

            const upload = document.querySelector("#upload2");
            if (upload.files.length > 0) {
                formData.append("upload", upload.files[0]);
            }
            formData.append("tipo", tipo);
            formData.append("token", token);
            formData.append("data_pagamento", data_pagamento);
            formData.append("valor", valor);
            formData.append("numeroParcelas", numeroParcelas);
            formData.append("descricao", descricao);
            //const divErros = document.querySelector('#erros')

            axios
                .post(`/api/new/payment/${id}`, formData, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    if (resp.data.erros.length === 0) {
                        window.location = resp.data.url;
                    } else {
                        for (let i = 0; i < resp.data.erros.length; i++) {
                            let elemento = document.querySelector(
                                `#${resp.data.erros[i].elemento}`
                            );
                            elemento.style.backgroundColor = "#FFC0CB";
                        }
                    }
                });
        }
    }

    function updateProcess() {
        const processo = document.querySelector("#processo").value;
        const valorEstimado = document.querySelector("#valorEstimado").value;
        const forum = document.querySelector("#forum").value;
        const inicioProcesso = document.querySelector("#inicioProcesso").value;
        const estimada = document.querySelector("#estimada").value;
        const acao = document.querySelector("#acao").value;
        const pasta = document.querySelector("#pasta").value;
        const observacao = document.querySelector("#observacao").value;
        const comentarios = document.querySelector("#comentarios").value;
        const advogado = document.querySelector("#advogado").value;
        const advAutor = document.querySelector("#advAutor").value;
        const autor = document.querySelector("#autor").value;
        const reu = document.querySelector("#reu").value;
        const divErros = document.querySelector("#erros");
        const divErrosAll = divErros.querySelectorAll(".row");

        axios
            .post(
                `/api/update/process/${id}`,
                {
                    token,
                    processo,
                    valorEstimado,
                    forum,
                    inicioProcesso,
                    estimada,
                    acao,
                    pasta,
                    observacao,
                    comentarios,
                    advogado,
                    reu,
                    autor,
                    advAutor,
                },
                {
                    withCredentials: true,
                }
            )
            .then((resp) => {
                if (resp.data == 400) {
                    window.location = `/ci/pagamentos/erro`;
                }
                if (resp.data.erros.length === 0) {
                    window.location = `/ci/pagamentos/${id}`;
                } else {
                    for (let i = 0; i < resp.data.erros.length; i++) {
                        for (let c = 0; c < divErrosAll.length; c++) {
                            divErrosAll[c].remove();
                        }

                        let elemento = document.querySelector(
                            `#${resp.data.erros[i].elemento}`
                        );
                        elemento.style.backgroundColor = "#FFC0CB";
                        let divRow = document.createElement("div"); // Cria o elemento div
                        divRow.className = "row mt-3"; // Adiciona uma margem superior de 3 unidades
                        //divRow.style.backgroundColor = '#FFC0CB' // Define a cor de fundo da div
                        divRow.style.padding = "10px"; // Define um espaçamento interno de 10 pixels
                        divRow.style.borderRadius = "5px"; // Define um raio de borda de 5 pixels
                        let divCol = document.createElement("div"); // Cria o elemento div
                        divCol.style.display = "inline-block";
                        divCol.style.backgroundColor = "#FFC0CB";
                        let divText = document.createTextNode(
                            `${resp.data.erros[i].mensagem}`
                        ); // Cria o elemento de texto
                        divCol.appendChild(divText); // Adiciona o texto como filho de divCol
                        divRow.appendChild(divCol);
                        divErros.appendChild(divRow);
                    }
                }
            });
    }

    function cadastrarPessoa() {
        const nome = document.querySelector("#nome").value;
        const telefone = document.querySelector("#telefone").value;
        const cpf_cnpj = document.querySelector("#cpf_cnpj").value;

        axios
            .post(
                `/api/new/partner/${id}`,
                { token, nome, telefone, cpf_cnpj },
                {
                    withCredentials: true,
                }
            )
            .then((resp) => {
                if (resp.data == 400) {
                    alert("Essa pessoa/cpf já está cadastrado!");
                }
                if (resp.data == 404) {
                    window.location = `/new/partner/erro`;
                }
                if (resp.data.erros.length === 0) {
                    window.location = `/ci/pagamentos/${id}`;
                } else {
                    for (let i = 0; i < resp.data.erros.length; i++) {
                        let elemento = document.querySelector(
                            `#${resp.data.erros[i].elemento}`
                        );
                        elemento.style.backgroundColor = "#FFC0CB";
                    }
                }
            });
    }

    function cadastrarReu() {
        const reu = document.querySelector("#nomeReu").value;

        axios
            .post(
                `/api/new/defendant/${id}`,
                { token, reu },
                {
                    withCredentials: true,
                }
            )
            .then((resp) => {
                if (resp.data == 400) {
                    alert("Essa pessoa já está cadastrada como Réu!");
                } else {
                    window.location = `/ci/pagamentos/${id}`;
                }
            });
    }

    function cadastrarAutor() {
        const autor = document.querySelector("#nomeAutor").value;

        axios
            .post(
                `/api/new/actor/${id}`,
                { token, autor },
                {
                    withCredentials: true,
                }
            )
            .then((resp) => {
                if (resp.data == 400) {
                    alert("Essa pessoa já está cadastrada como Autor!");
                } else {
                    window.location = `/ci/pagamentos/${id}`;
                }
            });
    }

    function cadastrarAdvogado() {
        const adv = document.querySelector("#nomeAdvogado").value;

        axios
            .post(
                `/api/new/attorney/${id}`,
                { token, adv },
                {
                    withCredentials: true,
                }
            )
            .then((resp) => {
                if (resp.data == 400) {
                    alert("Essa pessoa já está cadastrada como Advogado!");
                } else {
                    window.location = `/ci/pagamentos/${id}`;
                }
            });
    }

    function startPayment() {
        if (selectedPayment.tipo == "Acordo") {
            return (
                <div>
                    <label className="required" for="m-cadastrar-descricaoLbl">
                        Informe o tipo de guia
                    </label>

                    <select
                        class="form-control"
                        id="tipoEdit"
                        name="tipoEdit"
                        onClick={() => tipoPagamentoEdit()}
                        style={{ visibility: "visible", display: "block" }}
                        disabled
                    >
                        <option selected>{selectedPayment.tipo}</option>
                        <option>Auto Infração Trabalhista</option>
                        <option>Pagamento Acordo Execução</option>
                        <option>Honorários Técnicos</option>
                        <option>Custas AI</option>
                        <option>Depósito Judicial</option>
                        <option>Depósito Recursal RO</option>
                        <option>Depósito Recursal RR</option>
                        <option>Depósito Recursal AI</option>
                        <option>Perito Contador</option>
                        <option>Perito Médico</option>
                        <option>Previdência</option>
                        <option>Apólice RO</option>
                        <option>Apólice RR</option>
                        <option>Apólice AI</option>
                        <option>Depósito Judicial execução </option>
                        <option>Acordo</option>
                        <option>Custas RO</option>
                        <option>Custas RR</option>
                        <option>Deposito Judicial execução provisória</option>
                        <option>Pensão</option>
                        <option>Depósito Judicial NÃO TRABALHISTA</option>
                        <option>Custas processuais oficial de justiça</option>
                        <option>Taxa de mandato</option>
                    </select>
                    <div
                        id="classEdit"
                        className
                        style={{ visibility: "visible", display: "block" }}
                    >
                        <label className="required" htmlFor="m-cadastrar-nomes">
                            Nome
                        </label>
                        <input
                            id="nomes"
                            className="form-control"
                            maxLength={50}
                            type="text"
                            name="nomes"
                            value={selectedPayment.nome}
                            onChange={changeInputName}
                        />
                        <label className="required" htmlFor="m-cadastrar-banco">
                            Banco
                        </label>
                        <select
                            type="text"
                            id="banco"
                            className="form-control"
                            name="banco"
                        >
                            <option selected>{selectedPayment.banco}</option>
                            <option>1 - Banco do Brasil S.A.</option>
                            <option>3 - Banco da Amazônia S.A.</option>
                            <option>
                                4 - Banco do Nordeste do Brasil S.A.
                            </option>
                            <option>
                                7 - Banco Nacional de Desenvolvimento BNDES
                            </option>
                            <option>10 - Credicoamo</option>
                            <option>12 - Banco Inbursa S.A.</option>
                            <option>14 - Natixis Brasil S.A</option>
                            <option>17 - BNY Mellon Banco S.A.</option>
                            <option>18 - Banco Tricury S.A</option>
                            <option>
                                21 - BANESTES S.A. Banco do Estado do Espírito
                                Santo
                            </option>
                            <option>24 - Banco BANDEPE S.A.</option>
                            <option>25 - Banco Alfa S.A.</option>
                            <option>29 - Banco Itaú Consignado S.A.</option>
                            <option>33 - Banco Santander (Brasil) S.A.</option>
                            <option>36 - Banco Bradesco BBI S.A.</option>
                            <option>37 - Banco do Estado do Pará S.A.</option>
                            <option>40 - Banco Cargill S.A.</option>
                            <option>
                                41 - Banco do Estado do Rio Grande do Sul S.A.
                            </option>
                            <option>
                                47 - Banco do Estado de Sergipe S.A.
                            </option>
                            <option>62 - Hipercard Banco Múltiplo S.A.</option>
                            <option>63 - Banco Bradescard S.A.</option>
                            <option>65 - Banco Andbank (Brasil) S.A.</option>
                            <option>66 - Banco Morgan Stanley S.A</option>
                            <option>69 - Banco Crefisa S.A</option>
                            <option>70 - BRB - Banco de Brasília S.A.</option>
                            <option>74 - Banco J. Safra S.A.</option>
                            <option>75 - Banco ABN AMRO S.A.</option>
                            <option>76 - Banco KDB do Brasil S.A</option>
                            <option>77 - Banco Inter S.A.</option>
                            <option>82 - Banco Topázio S.A.</option>
                            <option>83 - Banco da China Brasil S.A.</option>
                            <option>94 - Banco Finaxis S.A.</option>
                            <option>95 - Travelex Banco de Câmbio S.A.</option>
                            <option>96 - Banco B3 S.A.</option>
                            <option>102 - Banco XP S.A.</option>
                            <option>104 - Caixa Econômica Federal</option>
                            <option>107 - Banco BOCOM BBM S.A.</option>
                            <option>
                                119 - Banco Western Union do Brasil S.A.
                            </option>
                            <option>120 - Banco Rodobens S.A.</option>
                            <option>121 - Banco Agibank S.A.</option>
                            <option>125 - Banco Genial S.A.</option>
                            <option>128 - MS Bank S.A. Banco de Câmbio</option>
                            <option>
                                129 - UBS Brasil Banco de Investimento S.A.
                            </option>
                            <option>144 - BEXS Banco de Câmbio S.A.</option>
                            <option>
                                169 - Banco Olé Bonsucesso Consignado S.A.
                            </option>
                            <option>184 - Banco Itaú BBA S.A.</option>
                            <option>208 - Banco BTG Pactual S.A.</option>
                            <option>212 - Banco Original S.A.</option>
                            <option>217 - Banco John Deere S.A.</option>
                            <option data-banco={218} value={218}>
                                218 - Banco BS2 S.A.
                            </option>
                            <option>
                                222 - Banco Credit Agricole Brasil S.A.
                            </option>
                            <option>224 - Banco Fibra S.A.</option>
                            <option>233 - Banco Cifra S.A.</option>
                            <option>237 - Banco Bradesco S.A.</option>
                            <option>243 - Banco Master S.A.</option>
                            <option>246 - Banco ABC Brasil S.A.</option>
                            <option>
                                249 - Banco Investcred Unibanco S.A.
                            </option>
                            <option>
                                250 - BCV - Banco de Crédito e Varejo S.A.
                            </option>
                            <option>254 - Paraná Banco S.A.</option>
                            <option>260 - Nu Pagamentos S.A</option>
                            <option>
                                269 - HSBC Brasil S.A. - Banco de Investimento
                            </option>
                            <option>276 - Banco Senff S.A.</option>
                            <option>290 - Pagseguro Internet S.A</option>
                            <option>
                                299 - Banco Sorocred S.A. - Banco Múltiplo
                                (AFINZ)
                            </option>
                            <option>318 - Banco BMG S.A.</option>
                            <option>
                                320 - China Construction Bank (Brasil) Banco
                                Múltiplo S.A.
                            </option>
                            <option>323 - Mercado Pago</option>
                            <option>341 - Itaú Unibanco S.A.</option>
                            <option>
                                366 - Banco Société Générale Brasil S.A.
                            </option>
                            <option>370 - Banco Mizuho do Brasil S.A.</option>
                            <option>376 - Banco J. P. Morgan S.A.</option>
                            <option>
                                389 - Banco Mercantil do Brasil S.A.
                            </option>
                            <option>
                                394 - Banco Bradesco Financiamentos S.A.
                            </option>
                            <option>
                                399 - Kirton Bank S.A. - Banco Múltiplo
                            </option>
                            <option>422 - Banco Safra S.A.</option>
                            <option>456 - Banco MUFG Brasil S.A.</option>
                            <option>
                                464 - Banco Sumitomo Mitsui Brasileiro S.A.
                            </option>
                            <option>
                                473 - Banco Caixa Geral - Brasil S.A.
                            </option>
                            <option>477 - Citibank N.A.</option>
                            <option>479 - Banco ItauBank S.A</option>
                            <option>
                                487 - Deutsche Bank S.A. - Banco Alemão
                            </option>
                            <option>
                                488 - JPMorgan Chase Bank, National Association
                            </option>
                            <option>492 - ING Bank N.V.</option>
                            <option>
                                505 - Banco Credit Suisse (Brasil) S.A.
                            </option>
                            <option>600 - Banco Luso Brasileiro S.A.</option>
                            <option>
                                604 - Banco Industrial do Brasil S.A.
                            </option>
                            <option>610 - Banco VR S.A.</option>
                            <option>611 - Banco Paulista S.A.</option>
                            <option>612 - Banco Guanabara S.A.</option>
                            <option>623 - Banco PAN S.A.</option>
                            <option>626 - Banco C6 Consignado S.A.</option>
                            <option>630 - Banco Smartbank S.A.</option>
                            <option>633 - Banco Rendimento S.A.</option>
                            <option>634 - Banco Triângulo S.A.</option>
                            <option>643 - Banco Pine S.A.</option>
                            <option>653 - Banco Voiter S.A.</option>
                            <option>654 - Banco Digimais S.A.</option>
                            <option>655 - Banco Votorantim S.A.</option>
                            <option>707 - Banco Daycoval S.A.</option>
                            <option>739 - Banco Cetelem S.A.</option>
                            <option>743 - Banco Semear S.A.</option>
                            <option>745 - Banco Citibank S.A.</option>
                            <option>746 - Banco Modal S.A.</option>
                            <option>
                                747 - Banco Rabobank International Brasil S.A.
                            </option>
                            <option>
                                748 - Banco Cooperativo Sicredi S.A.
                            </option>
                            <option>
                                751 - Scotiabank Brasil S.A. Banco Múltiplo
                            </option>
                            <option>752 - Banco BNP Paribas Brasil S.A.</option>
                            <option>
                                755 - Bank of America Merrill Lynch Banco
                                Múltiplo S.A.
                            </option>
                            <option>
                                756 - Banco Cooperativo do Brasil S.A. - BANCOOB
                            </option>{" "}
                        </select>
                        <label className="required" htmlFor="m-cadastrar-cpf">
                            CPF/CNPJ
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="cpf"
                            name="cpf"
                            onInput={formatCpf_Cnpj}
                            value={selectedPayment.cpf}
                            onChange={allInputChange}
                        />
                        <label
                            className="required"
                            htmlFor="m-cadastrar-agencia"
                        >
                            Agência
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={4}
                            minLength={4}
                            id="agencia"
                            name="agencia"
                            value={selectedPayment.agencia}
                            onChange={allInputChange}
                        />
                        <label className="required" htmlFor="m-cadastrar-conta">
                            Conta
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={11}
                            minLength={5}
                            id="conta"
                            name="conta"
                            value={selectedPayment.conta}
                            onChange={allInputChange}
                        />
                        <label
                            className="required"
                            htmlFor="m-cadastrar-digito"
                        >
                            Dígito
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={1}
                            minLength={1}
                            id="digito"
                            name="digito"
                            value={selectedPayment.digito}
                            onChange={allInputChange}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <label className="required" for="m-cadastrar-descricaoLbl">
                        Informe o tipo de guia
                    </label>
                    <select
                        class="form-control"
                        id="tipoEdit"
                        name="tipoEdit"
                        onClick={() => tipoPagamentoEdit()}
                        style={{ visibility: "visible", display: "block" }}
                        disabled
                    >
                        <option selected>{selectedPayment.tipo}</option>
                        <option>Auto Infração Trabalhista</option>
                        <option>Pagamento Acordo Execução</option>
                        <option>Honorários Técnicos</option>
                        <option>Custas AI</option>
                        <option>Depósito Judicial</option>
                        <option>Depósito Recursal RO</option>
                        <option>Depósito Recursal RR</option>
                        <option>Depósito Recursal AI</option>
                        <option>Perito Contador</option>
                        <option>Perito Médico</option>
                        <option>Previdência</option>
                        <option>Apólice RO</option>
                        <option>Apólice RR</option>
                        <option>Apólice AI</option>
                        <option>Depósito Judicial execução </option>
                        <option>Acordo</option>
                        <option>Custas RO</option>
                        <option>Custas RR</option>
                        <option>Deposito Judicial execução provisória</option>
                        <option>Pensão</option>
                        <option>Depósito Judicial NÃO TRABALHISTA</option>
                        <option>Custas processuais oficial de justiça</option>
                        <option>Taxa de mandato</option>
                    </select>
                    <div
                        id="classEdit"
                        className
                        style={{ visibility: "visible", display: "none" }}
                    >
                        <label className="required" htmlFor="m-cadastrar-nomes">
                            Nome
                        </label>
                        <input
                            className="form-control"
                            maxLength={50}
                            type="text"
                            id="nomes"
                            name="nomes"
                            value={selectedPayment.nome}
                            onChange={changeInputName}
                        />
                        <label className="required" htmlFor="m-cadastrar-banco">
                            Banco
                        </label>
                        <select
                            type="text"
                            id="banco"
                            className="form-control"
                            name="banco"
                        >
                            <option selected>{selectedPayment.banco}</option>
                            <option>1 - Banco do Brasil S.A.</option>
                            <option>3 - Banco da Amazônia S.A.</option>
                            <option>
                                4 - Banco do Nordeste do Brasil S.A.
                            </option>
                            <option>
                                7 - Banco Nacional de Desenvolvimento BNDES
                            </option>
                            <option>10 - Credicoamo</option>
                            <option>12 - Banco Inbursa S.A.</option>
                            <option>14 - Natixis Brasil S.A</option>
                            <option>17 - BNY Mellon Banco S.A.</option>
                            <option>18 - Banco Tricury S.A</option>
                            <option>
                                21 - BANESTES S.A. Banco do Estado do Espírito
                                Santo
                            </option>
                            <option>24 - Banco BANDEPE S.A.</option>
                            <option>25 - Banco Alfa S.A.</option>
                            <option>29 - Banco Itaú Consignado S.A.</option>
                            <option>33 - Banco Santander (Brasil) S.A.</option>
                            <option>36 - Banco Bradesco BBI S.A.</option>
                            <option>37 - Banco do Estado do Pará S.A.</option>
                            <option>40 - Banco Cargill S.A.</option>
                            <option>
                                41 - Banco do Estado do Rio Grande do Sul S.A.
                            </option>
                            <option>
                                47 - Banco do Estado de Sergipe S.A.
                            </option>
                            <option>62 - Hipercard Banco Múltiplo S.A.</option>
                            <option>63 - Banco Bradescard S.A.</option>
                            <option>65 - Banco Andbank (Brasil) S.A.</option>
                            <option>66 - Banco Morgan Stanley S.A</option>
                            <option>69 - Banco Crefisa S.A</option>
                            <option>70 - BRB - Banco de Brasília S.A.</option>
                            <option>74 - Banco J. Safra S.A.</option>
                            <option>75 - Banco ABN AMRO S.A.</option>
                            <option>76 - Banco KDB do Brasil S.A</option>
                            <option>77 - Banco Inter S.A.</option>
                            <option>82 - Banco Topázio S.A.</option>
                            <option>83 - Banco da China Brasil S.A.</option>
                            <option>94 - Banco Finaxis S.A.</option>
                            <option>95 - Travelex Banco de Câmbio S.A.</option>
                            <option>96 - Banco B3 S.A.</option>
                            <option>102 - Banco XP S.A.</option>
                            <option>104 - Caixa Econômica Federal</option>
                            <option>107 - Banco BOCOM BBM S.A.</option>
                            <option>
                                119 - Banco Western Union do Brasil S.A.
                            </option>
                            <option>120 - Banco Rodobens S.A.</option>
                            <option>121 - Banco Agibank S.A.</option>
                            <option>125 - Banco Genial S.A.</option>
                            <option>128 - MS Bank S.A. Banco de Câmbio</option>
                            <option>
                                129 - UBS Brasil Banco de Investimento S.A.
                            </option>
                            <option>144 - BEXS Banco de Câmbio S.A.</option>
                            <option>
                                169 - Banco Olé Bonsucesso Consignado S.A.
                            </option>
                            <option>184 - Banco Itaú BBA S.A.</option>
                            <option>208 - Banco BTG Pactual S.A.</option>
                            <option>212 - Banco Original S.A.</option>
                            <option>217 - Banco John Deere S.A.</option>
                            <option data-banco={218} value={218}>
                                218 - Banco BS2 S.A.
                            </option>
                            <option>
                                222 - Banco Credit Agricole Brasil S.A.
                            </option>
                            <option>224 - Banco Fibra S.A.</option>
                            <option>233 - Banco Cifra S.A.</option>
                            <option>237 - Banco Bradesco S.A.</option>
                            <option>243 - Banco Master S.A.</option>
                            <option>246 - Banco ABC Brasil S.A.</option>
                            <option>
                                249 - Banco Investcred Unibanco S.A.
                            </option>
                            <option>
                                250 - BCV - Banco de Crédito e Varejo S.A.
                            </option>
                            <option>254 - Paraná Banco S.A.</option>
                            <option>260 - Nu Pagamentos S.A</option>
                            <option>
                                269 - HSBC Brasil S.A. - Banco de Investimento
                            </option>
                            <option>276 - Banco Senff S.A.</option>
                            <option>290 - Pagseguro Internet S.A</option>
                            <option>
                                299 - Banco Sorocred S.A. - Banco Múltiplo
                                (AFINZ)
                            </option>
                            <option>318 - Banco BMG S.A.</option>
                            <option>
                                320 - China Construction Bank (Brasil) Banco
                                Múltiplo S.A.
                            </option>
                            <option>323 - Mercado Pago</option>
                            <option>341 - Itaú Unibanco S.A.</option>
                            <option>
                                366 - Banco Société Générale Brasil S.A.
                            </option>
                            <option>370 - Banco Mizuho do Brasil S.A.</option>
                            <option>376 - Banco J. P. Morgan S.A.</option>
                            <option>
                                389 - Banco Mercantil do Brasil S.A.
                            </option>
                            <option>
                                394 - Banco Bradesco Financiamentos S.A.
                            </option>
                            <option>
                                399 - Kirton Bank S.A. - Banco Múltiplo
                            </option>
                            <option>422 - Banco Safra S.A.</option>
                            <option>456 - Banco MUFG Brasil S.A.</option>
                            <option>
                                464 - Banco Sumitomo Mitsui Brasileiro S.A.
                            </option>
                            <option>
                                473 - Banco Caixa Geral - Brasil S.A.
                            </option>
                            <option>477 - Citibank N.A.</option>
                            <option>479 - Banco ItauBank S.A</option>
                            <option>
                                487 - Deutsche Bank S.A. - Banco Alemão
                            </option>
                            <option>
                                488 - JPMorgan Chase Bank, National Association
                            </option>
                            <option>492 - ING Bank N.V.</option>
                            <option>
                                505 - Banco Credit Suisse (Brasil) S.A.
                            </option>
                            <option>600 - Banco Luso Brasileiro S.A.</option>
                            <option>
                                604 - Banco Industrial do Brasil S.A.
                            </option>
                            <option>610 - Banco VR S.A.</option>
                            <option>611 - Banco Paulista S.A.</option>
                            <option>612 - Banco Guanabara S.A.</option>
                            <option>623 - Banco PAN S.A.</option>
                            <option>626 - Banco C6 Consignado S.A.</option>
                            <option>630 - Banco Smartbank S.A.</option>
                            <option>633 - Banco Rendimento S.A.</option>
                            <option>634 - Banco Triângulo S.A.</option>
                            <option>643 - Banco Pine S.A.</option>
                            <option>653 - Banco Voiter S.A.</option>
                            <option>654 - Banco Digimais S.A.</option>
                            <option>655 - Banco Votorantim S.A.</option>
                            <option>707 - Banco Daycoval S.A.</option>
                            <option>739 - Banco Cetelem S.A.</option>
                            <option>743 - Banco Semear S.A.</option>
                            <option>745 - Banco Citibank S.A.</option>
                            <option>746 - Banco Modal S.A.</option>
                            <option>
                                747 - Banco Rabobank International Brasil S.A.
                            </option>
                            <option>
                                748 - Banco Cooperativo Sicredi S.A.
                            </option>
                            <option>
                                751 - Scotiabank Brasil S.A. Banco Múltiplo
                            </option>
                            <option>752 - Banco BNP Paribas Brasil S.A.</option>
                            <option>
                                755 - Bank of America Merrill Lynch Banco
                                Múltiplo S.A.
                            </option>
                            <option>
                                756 - Banco Cooperativo do Brasil S.A. - BANCOOB
                            </option>{" "}
                        </select>
                        <label className="required" htmlFor="m-cadastrar-cpf">
                            CPF/CNPJ
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="cpf"
                            name="cpf"
                            onInput={formatCpf_Cnpj}
                            value={selectedPayment.cpf}
                            onChange={allInputChange}
                        />
                        <label
                            className="required"
                            htmlFor="m-cadastrar-agencia"
                        >
                            Agência
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={4}
                            minLength={4}
                            id="agencia"
                            name="agencia"
                            value={selectedPayment.agencia}
                            onChange={allInputChange}
                        />
                        <label className="required" htmlFor="m-cadastrar-conta">
                            Conta
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={11}
                            minLength={5}
                            id="conta"
                            name="conta"
                            value={selectedPayment.conta}
                            onChange={allInputChange}
                        />
                        <label
                            className="required"
                            htmlFor="m-cadastrar-digito"
                        >
                            Dígito
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={1}
                            minLength={1}
                            id="digito"
                            name="digito"
                            value={selectedPayment.digito}
                            onChange={allInputChange}
                        />
                    </div>
                </div>
            );
        }
    }

    function clickUpload3() {
        const inputFile = document.getElementById("upload3");
        inputFile.click();
    }

    function changeInputName(event) {
        const value = event.target.value;

        setSelectedPayment((prevState) => ({
            ...prevState,
            nome: value,
        }));
    }

    function allInputChange(event) {
        const { name, value } = event.target;
        setSelectedPayment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function allInputDescription(event) {
        const { name, value } = event.target;
        setResponse((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function moneyInputChange(event) {
        const { name, value } = event.target;

        const newValue = value
            .replace(/\D/g, "") // Remove tudo que não for número
            .replace(/^0+/, "") // Remove os zeros iniciais
            .padStart(3, "0") // Adiciona zeros à esquerda, caso necessário
            .replace(/(\d{1,})(\d{2})$/, "$1,$2") // Adiciona a vírgula decimal
            .replace(/(?=(\d{3})+(\D))\B/g, "."); // Adiciona o ponto como separador de milhares

        setSelectedPayment((prevState) => ({
            ...prevState,
            [name]: `R$ ${newValue}`,
        }));
    }

    function moneyInputChangeDescription(event) {
        const { name, value } = event.target;

        const newValue = value
            .replace(/\D/g, "") // Remove tudo que não for número
            .replace(/^0+/, "") // Remove os zeros iniciais
            .padStart(3, "0") // Adiciona zeros à esquerda, caso necessário
            .replace(/(\d{1,})(\d{2})$/, "$1,$2") // Adiciona a vírgula decimal
            .replace(/(?=(\d{3})+(\D))\B/g, "."); // Adiciona o ponto como separador de milhares

        setResponse((prevState) => ({
            ...prevState,
            [name]: `R$ ${newValue}`,
        }));
    }

    function controlAttachment() {
        if (responsePagamento.length > 0) {
            if (responsePagamento[0].tipo != "Acordo") {
                return (
                    <div
                        className="row"
                        id="pagamentoAnexo"
                        style={{ visibility: "visible", display: "block" }}
                    >
                        <div className="col-10">
                            <label for="m-cadastrar-descricaoLbl">
                                Novos anexos
                            </label>
                        </div>
                        <input
                            name="upload"
                            id="upload2"
                            type="file"
                            onChange={selectedAttachment}
                        ></input>
                        <div className="col-2">
                            <button
                                type="button"
                                className="btn btn-success"
                                title="Upload"
                                onClick={() => clickUpload2()}
                            >
                                <i className="fas fa-file-upload"></i>
                            </button>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div
                    className="row"
                    id="pagamentoAnexo"
                    style={{ visibility: "visible", display: "block" }}
                >
                    <div className="col-10">
                        <label for="m-cadastrar-descricaoLbl">
                            Novos anexos
                        </label>
                    </div>
                    <input
                        name="upload"
                        id="upload2"
                        type="file"
                        onChange={selectedAttachment}
                    ></input>
                    <div className="col-2">
                        <button
                            type="button"
                            className="btn btn-success"
                            title="Upload"
                            onClick={() => clickUpload2()}
                        >
                            <i className="fas fa-file-upload"></i>
                        </button>
                    </div>
                </div>
            );
        }
    }

    function generatePayment() {
        if (responsePagamento.length == 0) {
            return (
                <div>
                    <label className="required" for="m-cadastrar-descricaoLbl">
                        Informe o tipo de guia
                    </label>
                    <select
                        className="form-control"
                        id="tipo"
                        name="tipo"
                        onClick={() => tipoPagamento()}
                        style={{ visibility: "visible", display: "block" }}
                    >
                        <option>Auto Infração Trabalhista</option>
                        <option>Pagamento Acordo Execução</option>
                        <option>Honorários Técnicos</option>
                        <option>Custas AI</option>
                        <option>Depósito Judicial</option>
                        <option>Depósito Recursal RO</option>
                        <option>Depósito Recursal RR</option>
                        <option>Depósito Recursal AI</option>
                        <option>Perito Contador</option>
                        <option>Perito Médico</option>
                        <option>Previdência</option>
                        <option>Apólice RO</option>
                        <option>Apólice RR</option>
                        <option>Apólice AI</option>
                        <option>Depósito Judicial execução </option>
                        <option>Acordo</option>
                        <option>Custas RO</option>
                        <option>Custas RR</option>
                        <option>Deposito Judicial execução provisória</option>
                        <option>Pensão</option>
                        <option>Depósito Judicial NÃO TRABALHISTA</option>
                        <option>Custas processuais oficial de justiça</option>
                        <option>Taxa de mandato</option>
                    </select>
                    <div
                        id="classN"
                        className
                        style={{ visibility: "visible", display: "none" }}
                    >
                        <label className="required" htmlFor="m-cadastrar-nomes">
                            Nome
                        </label>
                        <input
                            className="form-control"
                            maxLength={50}
                            type="text"
                            id="nomes_novo"
                            name="nomes"
                        />
                        <label className="required" htmlFor="m-cadastrar-banco">
                            Banco
                        </label>
                        <select
                            type="text"
                            id="banco_novo"
                            className="form-control"
                            name="banco"
                        >
                            <option>1 - Banco do Brasil S.A.</option>
                            <option>3 - Banco da Amazônia S.A.</option>
                            <option>
                                4 - Banco do Nordeste do Brasil S.A.
                            </option>
                            <option>
                                7 - Banco Nacional de Desenvolvimento BNDES
                            </option>
                            <option>10 - Credicoamo</option>
                            <option>12 - Banco Inbursa S.A.</option>
                            <option>14 - Natixis Brasil S.A</option>
                            <option>17 - BNY Mellon Banco S.A.</option>
                            <option>18 - Banco Tricury S.A</option>
                            <option>
                                21 - BANESTES S.A. Banco do Estado do Espírito
                                Santo
                            </option>
                            <option>24 - Banco BANDEPE S.A.</option>
                            <option>25 - Banco Alfa S.A.</option>
                            <option>29 - Banco Itaú Consignado S.A.</option>
                            <option>33 - Banco Santander (Brasil) S.A.</option>
                            <option>36 - Banco Bradesco BBI S.A.</option>
                            <option>37 - Banco do Estado do Pará S.A.</option>
                            <option>40 - Banco Cargill S.A.</option>
                            <option>
                                41 - Banco do Estado do Rio Grande do Sul S.A.
                            </option>
                            <option>
                                47 - Banco do Estado de Sergipe S.A.
                            </option>
                            <option>62 - Hipercard Banco Múltiplo S.A.</option>
                            <option>63 - Banco Bradescard S.A.</option>
                            <option>65 - Banco Andbank (Brasil) S.A.</option>
                            <option>66 - Banco Morgan Stanley S.A</option>
                            <option>69 - Banco Crefisa S.A</option>
                            <option>70 - BRB - Banco de Brasília S.A.</option>
                            <option>74 - Banco J. Safra S.A.</option>
                            <option>75 - Banco ABN AMRO S.A.</option>
                            <option>76 - Banco KDB do Brasil S.A</option>
                            <option>77 - Banco Inter S.A.</option>
                            <option>82 - Banco Topázio S.A.</option>
                            <option>83 - Banco da China Brasil S.A.</option>
                            <option>94 - Banco Finaxis S.A.</option>
                            <option>95 - Travelex Banco de Câmbio S.A.</option>
                            <option>96 - Banco B3 S.A.</option>
                            <option>102 - Banco XP S.A.</option>
                            <option>104 - Caixa Econômica Federal</option>
                            <option>107 - Banco BOCOM BBM S.A.</option>
                            <option>
                                119 - Banco Western Union do Brasil S.A.
                            </option>
                            <option>120 - Banco Rodobens S.A.</option>
                            <option>121 - Banco Agibank S.A.</option>
                            <option>125 - Banco Genial S.A.</option>
                            <option>128 - MS Bank S.A. Banco de Câmbio</option>
                            <option>
                                129 - UBS Brasil Banco de Investimento S.A.
                            </option>
                            <option>144 - BEXS Banco de Câmbio S.A.</option>
                            <option>
                                169 - Banco Olé Bonsucesso Consignado S.A.
                            </option>
                            <option>184 - Banco Itaú BBA S.A.</option>
                            <option>208 - Banco BTG Pactual S.A.</option>
                            <option>212 - Banco Original S.A.</option>
                            <option>217 - Banco John Deere S.A.</option>
                            <option data-banco={218} value={218}>
                                218 - Banco BS2 S.A.
                            </option>
                            <option>
                                222 - Banco Credit Agricole Brasil S.A.
                            </option>
                            <option>224 - Banco Fibra S.A.</option>
                            <option>233 - Banco Cifra S.A.</option>
                            <option>237 - Banco Bradesco S.A.</option>
                            <option>243 - Banco Master S.A.</option>
                            <option>246 - Banco ABC Brasil S.A.</option>
                            <option>
                                249 - Banco Investcred Unibanco S.A.
                            </option>
                            <option>
                                250 - BCV - Banco de Crédito e Varejo S.A.
                            </option>
                            <option>254 - Paraná Banco S.A.</option>
                            <option>260 - Nu Pagamentos S.A</option>
                            <option>
                                269 - HSBC Brasil S.A. - Banco de Investimento
                            </option>
                            <option>276 - Banco Senff S.A.</option>
                            <option>290 - Pagseguro Internet S.A</option>
                            <option>
                                299 - Banco Sorocred S.A. - Banco Múltiplo
                                (AFINZ)
                            </option>
                            <option>318 - Banco BMG S.A.</option>
                            <option>
                                320 - China Construction Bank (Brasil) Banco
                                Múltiplo S.A.
                            </option>
                            <option>323 - Mercado Pago</option>
                            <option>341 - Itaú Unibanco S.A.</option>
                            <option>
                                366 - Banco Société Générale Brasil S.A.
                            </option>
                            <option>370 - Banco Mizuho do Brasil S.A.</option>
                            <option>376 - Banco J. P. Morgan S.A.</option>
                            <option>
                                389 - Banco Mercantil do Brasil S.A.
                            </option>
                            <option>
                                394 - Banco Bradesco Financiamentos S.A.
                            </option>
                            <option>
                                399 - Kirton Bank S.A. - Banco Múltiplo
                            </option>
                            <option>422 - Banco Safra S.A.</option>
                            <option>456 - Banco MUFG Brasil S.A.</option>
                            <option>
                                464 - Banco Sumitomo Mitsui Brasileiro S.A.
                            </option>
                            <option>
                                473 - Banco Caixa Geral - Brasil S.A.
                            </option>
                            <option>477 - Citibank N.A.</option>
                            <option>479 - Banco ItauBank S.A</option>
                            <option>
                                487 - Deutsche Bank S.A. - Banco Alemão
                            </option>
                            <option>
                                488 - JPMorgan Chase Bank, National Association
                            </option>
                            <option>492 - ING Bank N.V.</option>
                            <option>
                                505 - Banco Credit Suisse (Brasil) S.A.
                            </option>
                            <option>600 - Banco Luso Brasileiro S.A.</option>
                            <option>
                                604 - Banco Industrial do Brasil S.A.
                            </option>
                            <option>610 - Banco VR S.A.</option>
                            <option>611 - Banco Paulista S.A.</option>
                            <option>612 - Banco Guanabara S.A.</option>
                            <option>623 - Banco PAN S.A.</option>
                            <option>626 - Banco C6 Consignado S.A.</option>
                            <option>630 - Banco Smartbank S.A.</option>
                            <option>633 - Banco Rendimento S.A.</option>
                            <option>634 - Banco Triângulo S.A.</option>
                            <option>643 - Banco Pine S.A.</option>
                            <option>653 - Banco Voiter S.A.</option>
                            <option>654 - Banco Digimais S.A.</option>
                            <option>655 - Banco Votorantim S.A.</option>
                            <option>707 - Banco Daycoval S.A.</option>
                            <option>739 - Banco Cetelem S.A.</option>
                            <option>743 - Banco Semear S.A.</option>
                            <option>745 - Banco Citibank S.A.</option>
                            <option>746 - Banco Modal S.A.</option>
                            <option>
                                747 - Banco Rabobank International Brasil S.A.
                            </option>
                            <option>
                                748 - Banco Cooperativo Sicredi S.A.
                            </option>
                            <option>
                                751 - Scotiabank Brasil S.A. Banco Múltiplo
                            </option>
                            <option>752 - Banco BNP Paribas Brasil S.A.</option>
                            <option>
                                755 - Bank of America Merrill Lynch Banco
                                Múltiplo S.A.
                            </option>
                            <option>
                                756 - Banco Cooperativo do Brasil S.A. - BANCOOB
                            </option>{" "}
                        </select>
                        <label className="required" htmlFor="m-cadastrar-cpf">
                            CPF/CNPJ
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="cpf_novo"
                            onInput={formatCpf_Cnpj}
                            name="cpf"
                        />
                        <label
                            className="required"
                            htmlFor="m-cadastrar-agencia"
                        >
                            Agência
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={4}
                            minLength={4}
                            id="agencia_novo"
                            name="agencia"
                        />
                        <label className="required" htmlFor="m-cadastrar-conta">
                            Conta
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={11}
                            minLength={5}
                            id="conta_novo"
                            name="conta"
                        />
                        <label
                            className="required"
                            htmlFor="m-cadastrar-digito"
                        >
                            Dígito
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength={1}
                            minLength={1}
                            id="digito_novo"
                            name="digito"
                        />
                        <label
                            className="required"
                            htmlFor="m-cadastrar-entidadeLbl"
                        >
                            Informe o Número de Parcelas do Pagamento
                        </label>
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={1}
                            min={1}
                            id="numeroParcelas_novo"
                            name="numeroParcelas"
                        />
                    </div>
                </div>
            );
        } else {
            if (responsePagamento[0].tipo == "Acordo") {
                return (
                    <div>
                        <label
                            className="required"
                            for="m-cadastrar-descricaoLbl"
                        >
                            Informe o tipo de guia
                        </label>
                        <input
                            className="text"
                            type="text"
                            name="tipo"
                            style={{ visibility: "visible", display: "none" }}
                            value="Acordo"
                        />
                        <select
                            class="form-control"
                            id="tipo"
                            name="tipo"
                            onClick={() => tipoPagamento()}
                            style={{ visibility: "visible", display: "block" }}
                            disabled
                        >
                            <option>Auto Infração Trabalhista</option>
                            <option>Pagamento Acordo Execução</option>
                            <option>Honorários Técnicos</option>
                            <option>Custas AI</option>
                            <option>Depósito Judicial</option>
                            <option>Depósito Recursal RO</option>
                            <option>Depósito Recursal RR</option>
                            <option>Depósito Recursal AI</option>
                            <option>Perito Contador</option>
                            <option>Perito Médico</option>
                            <option>Previdência</option>
                            <option>Apólice RO</option>
                            <option>Apólice RR</option>
                            <option>Apólice AI</option>
                            <option>Depósito Judicial execução </option>
                            <option selected>Acordo</option>
                            <option>Custas RO</option>
                            <option>Custas RR</option>
                            <option>
                                Deposito Judicial execução provisória
                            </option>
                            <option>Pensão</option>
                            <option>Depósito Judicial NÃO TRABALHISTA</option>
                            <option>
                                Custas processuais oficial de justiça
                            </option>
                            <option>Taxa de mandato</option>
                        </select>
                        <div
                            id="classN"
                            className
                            style={{ visibility: "visible", display: "block" }}
                        >
                            <label
                                className="required"
                                htmlFor="m-cadastrar-nomes"
                            >
                                Nome
                            </label>
                            <input
                                className="form-control"
                                maxLength={50}
                                type="text"
                                id="nomes_novo"
                                name="nomes"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-banco"
                            >
                                Banco
                            </label>
                            <select
                                type="text"
                                id="banco_novo"
                                className="form-control"
                                name="banco"
                            >
                                <option>1 - Banco do Brasil S.A.</option>
                                <option>3 - Banco da Amazônia S.A.</option>
                                <option>
                                    4 - Banco do Nordeste do Brasil S.A.
                                </option>
                                <option>
                                    7 - Banco Nacional de Desenvolvimento BNDES
                                </option>
                                <option>10 - Credicoamo</option>
                                <option>12 - Banco Inbursa S.A.</option>
                                <option>14 - Natixis Brasil S.A</option>
                                <option>17 - BNY Mellon Banco S.A.</option>
                                <option>18 - Banco Tricury S.A</option>
                                <option>
                                    21 - BANESTES S.A. Banco do Estado do
                                    Espírito Santo
                                </option>
                                <option>24 - Banco BANDEPE S.A.</option>
                                <option>25 - Banco Alfa S.A.</option>
                                <option>29 - Banco Itaú Consignado S.A.</option>
                                <option>
                                    33 - Banco Santander (Brasil) S.A.
                                </option>
                                <option>36 - Banco Bradesco BBI S.A.</option>
                                <option>
                                    37 - Banco do Estado do Pará S.A.
                                </option>
                                <option>40 - Banco Cargill S.A.</option>
                                <option>
                                    41 - Banco do Estado do Rio Grande do Sul
                                    S.A.
                                </option>
                                <option>
                                    47 - Banco do Estado de Sergipe S.A.
                                </option>
                                <option>
                                    62 - Hipercard Banco Múltiplo S.A.
                                </option>
                                <option>63 - Banco Bradescard S.A.</option>
                                <option>
                                    65 - Banco Andbank (Brasil) S.A.
                                </option>
                                <option>66 - Banco Morgan Stanley S.A</option>
                                <option>69 - Banco Crefisa S.A</option>
                                <option>
                                    70 - BRB - Banco de Brasília S.A.
                                </option>
                                <option>74 - Banco J. Safra S.A.</option>
                                <option>75 - Banco ABN AMRO S.A.</option>
                                <option>76 - Banco KDB do Brasil S.A</option>
                                <option>77 - Banco Inter S.A.</option>
                                <option>82 - Banco Topázio S.A.</option>
                                <option>83 - Banco da China Brasil S.A.</option>
                                <option>94 - Banco Finaxis S.A.</option>
                                <option>
                                    95 - Travelex Banco de Câmbio S.A.
                                </option>
                                <option>96 - Banco B3 S.A.</option>
                                <option>102 - Banco XP S.A.</option>
                                <option>104 - Caixa Econômica Federal</option>
                                <option>107 - Banco BOCOM BBM S.A.</option>
                                <option>
                                    119 - Banco Western Union do Brasil S.A.
                                </option>
                                <option>120 - Banco Rodobens S.A.</option>
                                <option>121 - Banco Agibank S.A.</option>
                                <option>125 - Banco Genial S.A.</option>
                                <option>
                                    128 - MS Bank S.A. Banco de Câmbio
                                </option>
                                <option>
                                    129 - UBS Brasil Banco de Investimento S.A.
                                </option>
                                <option>144 - BEXS Banco de Câmbio S.A.</option>
                                <option>
                                    169 - Banco Olé Bonsucesso Consignado S.A.
                                </option>
                                <option>184 - Banco Itaú BBA S.A.</option>
                                <option>208 - Banco BTG Pactual S.A.</option>
                                <option>212 - Banco Original S.A.</option>
                                <option>217 - Banco John Deere S.A.</option>
                                <option data-banco={218} value={218}>
                                    218 - Banco BS2 S.A.
                                </option>
                                <option>
                                    222 - Banco Credit Agricole Brasil S.A.
                                </option>
                                <option>224 - Banco Fibra S.A.</option>
                                <option>233 - Banco Cifra S.A.</option>
                                <option>237 - Banco Bradesco S.A.</option>
                                <option>243 - Banco Master S.A.</option>
                                <option>246 - Banco ABC Brasil S.A.</option>
                                <option>
                                    249 - Banco Investcred Unibanco S.A.
                                </option>
                                <option>
                                    250 - BCV - Banco de Crédito e Varejo S.A.
                                </option>
                                <option>254 - Paraná Banco S.A.</option>
                                <option>260 - Nu Pagamentos S.A</option>
                                <option>
                                    269 - HSBC Brasil S.A. - Banco de
                                    Investimento
                                </option>
                                <option>276 - Banco Senff S.A.</option>
                                <option>290 - Pagseguro Internet S.A</option>
                                <option>
                                    299 - Banco Sorocred S.A. - Banco Múltiplo
                                    (AFINZ)
                                </option>
                                <option>318 - Banco BMG S.A.</option>
                                <option>
                                    320 - China Construction Bank (Brasil) Banco
                                    Múltiplo S.A.
                                </option>
                                <option>323 - Mercado Pago</option>
                                <option>341 - Itaú Unibanco S.A.</option>
                                <option>
                                    366 - Banco Société Générale Brasil S.A.
                                </option>
                                <option>
                                    370 - Banco Mizuho do Brasil S.A.
                                </option>
                                <option>376 - Banco J. P. Morgan S.A.</option>
                                <option>
                                    389 - Banco Mercantil do Brasil S.A.
                                </option>
                                <option>
                                    394 - Banco Bradesco Financiamentos S.A.
                                </option>
                                <option>
                                    399 - Kirton Bank S.A. - Banco Múltiplo
                                </option>
                                <option>422 - Banco Safra S.A.</option>
                                <option>456 - Banco MUFG Brasil S.A.</option>
                                <option>
                                    464 - Banco Sumitomo Mitsui Brasileiro S.A.
                                </option>
                                <option>
                                    473 - Banco Caixa Geral - Brasil S.A.
                                </option>
                                <option>477 - Citibank N.A.</option>
                                <option>479 - Banco ItauBank S.A</option>
                                <option>
                                    487 - Deutsche Bank S.A. - Banco Alemão
                                </option>
                                <option>
                                    488 - JPMorgan Chase Bank, National
                                    Association
                                </option>
                                <option>492 - ING Bank N.V.</option>
                                <option>
                                    505 - Banco Credit Suisse (Brasil) S.A.
                                </option>
                                <option>
                                    600 - Banco Luso Brasileiro S.A.
                                </option>
                                <option>
                                    604 - Banco Industrial do Brasil S.A.
                                </option>
                                <option>610 - Banco VR S.A.</option>
                                <option>611 - Banco Paulista S.A.</option>
                                <option>612 - Banco Guanabara S.A.</option>
                                <option>623 - Banco PAN S.A.</option>
                                <option>626 - Banco C6 Consignado S.A.</option>
                                <option>630 - Banco Smartbank S.A.</option>
                                <option>633 - Banco Rendimento S.A.</option>
                                <option>634 - Banco Triângulo S.A.</option>
                                <option>643 - Banco Pine S.A.</option>
                                <option>653 - Banco Voiter S.A.</option>
                                <option>654 - Banco Digimais S.A.</option>
                                <option>655 - Banco Votorantim S.A.</option>
                                <option>707 - Banco Daycoval S.A.</option>
                                <option>739 - Banco Cetelem S.A.</option>
                                <option>743 - Banco Semear S.A.</option>
                                <option>745 - Banco Citibank S.A.</option>
                                <option>746 - Banco Modal S.A.</option>
                                <option>
                                    747 - Banco Rabobank International Brasil
                                    S.A.
                                </option>
                                <option>
                                    748 - Banco Cooperativo Sicredi S.A.
                                </option>
                                <option>
                                    751 - Scotiabank Brasil S.A. Banco Múltiplo
                                </option>
                                <option>
                                    752 - Banco BNP Paribas Brasil S.A.
                                </option>
                                <option>
                                    755 - Bank of America Merrill Lynch Banco
                                    Múltiplo S.A.
                                </option>
                                <option>
                                    756 - Banco Cooperativo do Brasil S.A. -
                                    BANCOOB
                                </option>{" "}
                            </select>
                            <label
                                className="required"
                                htmlFor="m-cadastrar-cpf"
                            >
                                CPF/CNPJ
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                id="cpf_novo"
                                onInput={formatCpf_Cnpj}
                                name="cpf"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-agencia"
                            >
                                Agência
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={4}
                                minLength={4}
                                id="agencia_novo"
                                name="agencia"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-conta"
                            >
                                Conta
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={11}
                                minLength={5}
                                id="conta_novo"
                                name="conta"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-digito"
                            >
                                Dígito
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={1}
                                minLength={1}
                                id="digito_novo"
                                name="digito"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-entidadeLbl"
                            >
                                Informe o Número de Parcelas do Pagamento
                            </label>
                            <input
                                className="form-control"
                                type="number"
                                defaultValue={1}
                                min={1}
                                id="numeroParcelas_novo"
                                name="numeroParcelas"
                            />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <label for="m-cadastrar-descricaoLbl">
                            Informe o tipo de guia
                        </label>
                        <select
                            class="form-control"
                            id="tipo"
                            name="tipo"
                            onClick={() => tipoPagamento()}
                            style={{ visibility: "visible", display: "block" }}
                        >
                            <option>Auto Infração Trabalhista</option>
                            <option>Pagamento Acordo Execução</option>
                            <option>Honorários Técnicos</option>
                            <option>Custas AI</option>
                            <option>Depósito Judicial</option>
                            <option>Depósito Recursal RO</option>
                            <option>Depósito Recursal RR</option>
                            <option>Depósito Recursal AI</option>
                            <option>Perito Contador</option>
                            <option>Perito Médico</option>
                            <option>Previdência</option>
                            <option>Apólice RO</option>
                            <option>Apólice RR</option>
                            <option>Apólice AI</option>
                            <option>Depósito Judicial execução </option>
                            <option>Acordo</option>
                            <option>Custas RO</option>
                            <option>Custas RR</option>
                            <option>
                                Deposito Judicial execução provisória
                            </option>
                            <option>Pensão</option>
                            <option>Depósito Judicial NÃO TRABALHISTA</option>
                            <option>
                                Custas processuais oficial de justiça
                            </option>
                            <option>Taxa de mandato</option>
                        </select>
                        <div
                            id="classN"
                            className
                            style={{ visibility: "visible", display: "none" }}
                        >
                            <label
                                className="required"
                                htmlFor="m-cadastrar-nomes"
                            >
                                Nome
                            </label>
                            <input
                                className="form-control"
                                maxLength={50}
                                type="text"
                                id="nomes_novo"
                                name="nomes"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-banco"
                            >
                                Banco
                            </label>
                            <select
                                type="text"
                                id="banco_novo"
                                className="form-control"
                                name="banco"
                            >
                                <option>1 - Banco do Brasil S.A.</option>
                                <option>3 - Banco da Amazônia S.A.</option>
                                <option>
                                    4 - Banco do Nordeste do Brasil S.A.
                                </option>
                                <option>
                                    7 - Banco Nacional de Desenvolvimento BNDES
                                </option>
                                <option>10 - Credicoamo</option>
                                <option>12 - Banco Inbursa S.A.</option>
                                <option>14 - Natixis Brasil S.A</option>
                                <option>17 - BNY Mellon Banco S.A.</option>
                                <option>18 - Banco Tricury S.A</option>
                                <option>
                                    21 - BANESTES S.A. Banco do Estado do
                                    Espírito Santo
                                </option>
                                <option>24 - Banco BANDEPE S.A.</option>
                                <option>25 - Banco Alfa S.A.</option>
                                <option>29 - Banco Itaú Consignado S.A.</option>
                                <option>
                                    33 - Banco Santander (Brasil) S.A.
                                </option>
                                <option>36 - Banco Bradesco BBI S.A.</option>
                                <option>
                                    37 - Banco do Estado do Pará S.A.
                                </option>
                                <option>40 - Banco Cargill S.A.</option>
                                <option>
                                    41 - Banco do Estado do Rio Grande do Sul
                                    S.A.
                                </option>
                                <option>
                                    47 - Banco do Estado de Sergipe S.A.
                                </option>
                                <option>
                                    62 - Hipercard Banco Múltiplo S.A.
                                </option>
                                <option>63 - Banco Bradescard S.A.</option>
                                <option>
                                    65 - Banco Andbank (Brasil) S.A.
                                </option>
                                <option>66 - Banco Morgan Stanley S.A</option>
                                <option>69 - Banco Crefisa S.A</option>
                                <option>
                                    70 - BRB - Banco de Brasília S.A.
                                </option>
                                <option>74 - Banco J. Safra S.A.</option>
                                <option>75 - Banco ABN AMRO S.A.</option>
                                <option>76 - Banco KDB do Brasil S.A</option>
                                <option>77 - Banco Inter S.A.</option>
                                <option>82 - Banco Topázio S.A.</option>
                                <option>83 - Banco da China Brasil S.A.</option>
                                <option>94 - Banco Finaxis S.A.</option>
                                <option>
                                    95 - Travelex Banco de Câmbio S.A.
                                </option>
                                <option>96 - Banco B3 S.A.</option>
                                <option>102 - Banco XP S.A.</option>
                                <option>104 - Caixa Econômica Federal</option>
                                <option>107 - Banco BOCOM BBM S.A.</option>
                                <option>
                                    119 - Banco Western Union do Brasil S.A.
                                </option>
                                <option>120 - Banco Rodobens S.A.</option>
                                <option>121 - Banco Agibank S.A.</option>
                                <option>125 - Banco Genial S.A.</option>
                                <option>
                                    128 - MS Bank S.A. Banco de Câmbio
                                </option>
                                <option>
                                    129 - UBS Brasil Banco de Investimento S.A.
                                </option>
                                <option>144 - BEXS Banco de Câmbio S.A.</option>
                                <option>
                                    169 - Banco Olé Bonsucesso Consignado S.A.
                                </option>
                                <option>184 - Banco Itaú BBA S.A.</option>
                                <option>208 - Banco BTG Pactual S.A.</option>
                                <option>212 - Banco Original S.A.</option>
                                <option>217 - Banco John Deere S.A.</option>
                                <option data-banco={218} value={218}>
                                    218 - Banco BS2 S.A.
                                </option>
                                <option>
                                    222 - Banco Credit Agricole Brasil S.A.
                                </option>
                                <option>224 - Banco Fibra S.A.</option>
                                <option>233 - Banco Cifra S.A.</option>
                                <option>237 - Banco Bradesco S.A.</option>
                                <option>243 - Banco Master S.A.</option>
                                <option>246 - Banco ABC Brasil S.A.</option>
                                <option>
                                    249 - Banco Investcred Unibanco S.A.
                                </option>
                                <option>
                                    250 - BCV - Banco de Crédito e Varejo S.A.
                                </option>
                                <option>254 - Paraná Banco S.A.</option>
                                <option>260 - Nu Pagamentos S.A</option>
                                <option>
                                    269 - HSBC Brasil S.A. - Banco de
                                    Investimento
                                </option>
                                <option>276 - Banco Senff S.A.</option>
                                <option>290 - Pagseguro Internet S.A</option>
                                <option>
                                    299 - Banco Sorocred S.A. - Banco Múltiplo
                                    (AFINZ)
                                </option>
                                <option>318 - Banco BMG S.A.</option>
                                <option>
                                    320 - China Construction Bank (Brasil) Banco
                                    Múltiplo S.A.
                                </option>
                                <option>323 - Mercado Pago</option>
                                <option>341 - Itaú Unibanco S.A.</option>
                                <option>
                                    366 - Banco Société Générale Brasil S.A.
                                </option>
                                <option>
                                    370 - Banco Mizuho do Brasil S.A.
                                </option>
                                <option>376 - Banco J. P. Morgan S.A.</option>
                                <option>
                                    389 - Banco Mercantil do Brasil S.A.
                                </option>
                                <option>
                                    394 - Banco Bradesco Financiamentos S.A.
                                </option>
                                <option>
                                    399 - Kirton Bank S.A. - Banco Múltiplo
                                </option>
                                <option>422 - Banco Safra S.A.</option>
                                <option>456 - Banco MUFG Brasil S.A.</option>
                                <option>
                                    464 - Banco Sumitomo Mitsui Brasileiro S.A.
                                </option>
                                <option>
                                    473 - Banco Caixa Geral - Brasil S.A.
                                </option>
                                <option>477 - Citibank N.A.</option>
                                <option>479 - Banco ItauBank S.A</option>
                                <option>
                                    487 - Deutsche Bank S.A. - Banco Alemão
                                </option>
                                <option>
                                    488 - JPMorgan Chase Bank, National
                                    Association
                                </option>
                                <option>492 - ING Bank N.V.</option>
                                <option>
                                    505 - Banco Credit Suisse (Brasil) S.A.
                                </option>
                                <option>
                                    600 - Banco Luso Brasileiro S.A.
                                </option>
                                <option>
                                    604 - Banco Industrial do Brasil S.A.
                                </option>
                                <option>610 - Banco VR S.A.</option>
                                <option>611 - Banco Paulista S.A.</option>
                                <option>612 - Banco Guanabara S.A.</option>
                                <option>623 - Banco PAN S.A.</option>
                                <option>626 - Banco C6 Consignado S.A.</option>
                                <option>630 - Banco Smartbank S.A.</option>
                                <option>633 - Banco Rendimento S.A.</option>
                                <option>634 - Banco Triângulo S.A.</option>
                                <option>643 - Banco Pine S.A.</option>
                                <option>653 - Banco Voiter S.A.</option>
                                <option>654 - Banco Digimais S.A.</option>
                                <option>655 - Banco Votorantim S.A.</option>
                                <option>707 - Banco Daycoval S.A.</option>
                                <option>739 - Banco Cetelem S.A.</option>
                                <option>743 - Banco Semear S.A.</option>
                                <option>745 - Banco Citibank S.A.</option>
                                <option>746 - Banco Modal S.A.</option>
                                <option>
                                    747 - Banco Rabobank International Brasil
                                    S.A.
                                </option>
                                <option>
                                    748 - Banco Cooperativo Sicredi S.A.
                                </option>
                                <option>
                                    751 - Scotiabank Brasil S.A. Banco Múltiplo
                                </option>
                                <option>
                                    752 - Banco BNP Paribas Brasil S.A.
                                </option>
                                <option>
                                    755 - Bank of America Merrill Lynch Banco
                                    Múltiplo S.A.
                                </option>
                                <option>
                                    756 - Banco Cooperativo do Brasil S.A. -
                                    BANCOOB
                                </option>{" "}
                            </select>
                            <label
                                className="required"
                                htmlFor="m-cadastrar-cpf"
                            >
                                CPF/CNPJ
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                id="cpf_novo"
                                onInput={formatCpf_Cnpj}
                                name="cpf"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-agencia"
                            >
                                Agência
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={4}
                                minLength={4}
                                id="agencia_novo"
                                name="agencia"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-conta"
                            >
                                Conta
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={11}
                                minLength={5}
                                id="conta_novo"
                                name="conta"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-digito"
                            >
                                Dígito
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={1}
                                minLength={1}
                                id="digito_novo"
                                name="digito"
                            />
                            <label
                                className="required"
                                htmlFor="m-cadastrar-entidadeLbl"
                            >
                                Informe o Número de Parcelas do Pagamento
                            </label>
                            <input
                                className="form-control"
                                type="number"
                                defaultValue={1}
                                min={1}
                                id="numeroParcelas_novo"
                                name="numeroParcelas"
                            />
                        </div>
                    </div>
                );
            }
        }
    }

    function apagarAnexo() {
        console.log("apagando....");
        document.querySelector("#anexoEdit").setAttribute("disabled", "");
        document.querySelector("#anexoDiv").style.display = "none";
    }

    function dataFormato(date) {
        const dataAtual = new Date(date);
        return `${
            dataAtual.getUTCDate() < 10 ? "0" : ""
        }${dataAtual.getUTCDate()}/${
            dataAtual.getUTCMonth() + 1 < 10 ? "0" : ""
        }${dataAtual.getUTCMonth() + 1}/${dataAtual.getUTCFullYear()}`;
        //return `${dataAtual.getDate()<10 ? '0': ''}${dataAtual.getDate()}/${dataAtual.getMonth()+1<10 ? "0": ""}${dataAtual.getMonth()+1}/${dataAtual.getFullYear()}`
        // console.log(date)
        // return `${date.getDate()<10 ? '0': ''}${date.getDate()}/${date.getMonth()+1<10 ? "0": ""}${date.getMonth()+1}/${date.getFullYear()}`
    }

    function handleInputChangeCpf(event) {
        const { value } = event.target;
        console.log("cpf formatação");
        // Remove tudo que não for número
        const newValue = value.replace(/\D/g, "");

        // Verifica se o valor tem 11 dígitos
        if (newValue.length === 11) {
            // Formata o CPF
            const formattedValue = newValue.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4"
            );
            setValueCpf(formattedValue);
        } else if (newValue.length === 14) {
            // Formata o CNPJ
            const formattedValue = newValue.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
            );
            setValueCpf(formattedValue);
        } else {
            // Caso contrário, exibe o valor sem formatação
            setValueCpf(newValue);
        }
    }

    function deleteDocumento(n) {
        axios
            .post(
                `/api/remove/document/${n}`,
                { token, responseParam },
                {
                    withCredentials: true,
                    timeout: 10000,
                }
            )
            .then((response) => {
                console.log("---------------------<><>Documentos");
                window.location = response.data;
            })
            .catch((error) => {
                if (axios.isAxiosError(error)) {
                    console.error("Erro Axios:", error.message);
                    console.error("Configuração da solicitação:", error.config);
                } else {
                    console.error(
                        "Erro não relacionado ao Axios:",
                        error.message
                    );
                }
            });
    }

    function handleInputChangeCel(event) {
        let { value } = event.target;

        // Remove tudo que não for número
        value = value.replace(/\D/g, "");

        if (value.length > 11) {
            // Limita o número de caracteres a 11
            value = value.slice(0, 11);
        }

        if (value.length === 11) {
            // Formata o número de celular
            const formattedValue = value.replace(
                /(\d{2})(\d{5})(\d{4})/,
                "($1) $2-$3"
            );
            setValueCel(formattedValue);
        } else {
            // Caso contrário, exibe o valor sem formatação
            setValueCel(value);
        }
    }

    // função para formatar o campo de cpf/cnpj (cpf: 000.000.000-00) ou (cnpj: 00.000.000/0000-00)
    function formatCpf_Cnpj(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito

        let novoValor = "";

        if (valor.length <= 11) {
            // CPF
            novoValor = valor.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4"
            );
        } else {
            // CNPJ
            novoValor = valor.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
                "$1.$2.$3/$4-$5"
            );
        }

        campo.value = novoValor;
    }

    // Função para lidar com a seleção de um único documento
    const handleSelectDoc = (id) => {
        setSelectedDocs((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((docId) => docId !== id)
                : [...prevSelected, id]
        );
    };

    // Função para selecionar ou desmarcar todos os documentos
    const handleSelectAll = () => {
        if (selectedDocs.length === responseDocs.length) {
            setSelectedDocs([]);
        } else {
            setSelectedDocs(responseDocs.map((doc) => doc.id));
        }
    };

    // Função para baixar documentos selecionados
    const downloadSelected = () => {
        if (selectedDocs.length === 0) return;

        setIsDownloading(true);
        selectedDocs.forEach((id) => {
            const link = document.createElement("a");
            link.href = `https://guias.almeidaenogueira.com.br/api/download/documento/${id}`;
            link.download = "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
        setIsDownloading(false);
    };

    // Função para baixar todos os documentos
    const downloadAll = () => {
        setIsDownloading(true);
        responseDocs.forEach((doc) => {
            const link = document.createElement("a");
            link.href = `https://guias.almeidaenogueira.com.br/api/download/documento/${doc.id}`;
            link.download = "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
        setIsDownloading(false);
    };

    return (
        <>
            <section class="content">
                <div className="container-fluid" style={{ marginTop: 18 }}>
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div className="row">
                                        <div className="container center-element">
                                            <ul
                                                class="nav nav-tabs nav-tabs-light flex-center"
                                                role="tablist"
                                            >
                                                <li
                                                    role="presentation"
                                                    className="nav-item"
                                                    onClick={() =>
                                                        atualizarTitulo(1)
                                                    }
                                                >
                                                    <a
                                                        href="#primeira-aba"
                                                        class="nav-link active"
                                                        aria-controls="primeira-aba"
                                                        role="tab"
                                                        data-toggle="tab"
                                                        aria-expanded="true"
                                                    >
                                                        <span
                                                            class="visible-xs visible-sm"
                                                            title=""
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            data-original-title="tab1"
                                                        ></span>
                                                        <span class="hidden-xs hidden-sm">
                                                            Descrição
                                                        </span>
                                                    </a>
                                                </li>

                                                <li
                                                    role="presentation"
                                                    class="nav-item"
                                                    onClick={() =>
                                                        atualizarTitulo(2)
                                                    }
                                                >
                                                    <a
                                                        href="#segunda-aba"
                                                        class="nav-link"
                                                        aria-controls="segunda-aba"
                                                        role="tab"
                                                        data-toggle="tab"
                                                        aria-expanded="false"
                                                    >
                                                        <span
                                                            class="visible-xs visible-sm"
                                                            title=""
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            data-original-title="segunda-aba"
                                                        ></span>
                                                        <span class="hidden-xs hidden-sm">
                                                            Anexo
                                                        </span>
                                                    </a>
                                                </li>

                                                <li
                                                    role="presentation"
                                                    class="nav-item"
                                                    onClick={() =>
                                                        atualizarTitulo(6)
                                                    }
                                                >
                                                    <a
                                                        href="#sexta-aba"
                                                        class="nav-link"
                                                        aria-controls="sexta-aba"
                                                        role="tab"
                                                        data-toggle="tab"
                                                        aria-expanded="false"
                                                    >
                                                        <span
                                                            class="visible-xs visible-sm"
                                                            title=""
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            data-original-title="tab6"
                                                        ></span>
                                                        <span class="hidden-xs hidden-sm">
                                                            Pagamentos
                                                        </span>
                                                    </a>
                                                </li>

                                                <li
                                                    role="presentation"
                                                    class="nav-item"
                                                    onClick={() =>
                                                        atualizarTitulo(7)
                                                    }
                                                >
                                                    <a
                                                        href="#setima-aba"
                                                        class="nav-link"
                                                        aria-controls="setima-aba"
                                                        role="tab"
                                                        data-toggle="tab"
                                                        aria-expanded="false"
                                                    >
                                                        <span
                                                            class="visible-xs visible-sm"
                                                            title=""
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            data-original-title="tab7"
                                                        ></span>
                                                        <span class="hidden-xs hidden-sm">
                                                            Workflow
                                                        </span>
                                                    </a>
                                                </li>
                                                <li
                                                    role="presentation"
                                                    class="nav-item"
                                                    onClick={() =>
                                                        atualizarTitulo(8)
                                                    }
                                                >
                                                    <a
                                                        href="#oitava-aba"
                                                        class="nav-link"
                                                        aria-controls="oitava-aba"
                                                        role="tab"
                                                        data-toggle="tab"
                                                        aria-expanded="false"
                                                    >
                                                        <span
                                                            class="visible-xs visible-sm"
                                                            title=""
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            data-original-title="oitava-aba"
                                                        ></span>
                                                        <span class="hidden-xs hidden-sm">
                                                            Documentos
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className="row"
                                        style={{ marginTop: -1 }}
                                    >
                                        <div className="col-12">
                                            <div class="card-header bg-info">
                                                <div class="inner text-center"></div>
                                                <h3
                                                    className="text-center"
                                                    id="titulo"
                                                >
                                                    Dados da Requisição
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div
                                            class="tab-pane fade show active"
                                            id="primeira-aba"
                                            role="tabpanel"
                                        >
                                            <div
                                                className="row"
                                                id="erros"
                                                style={{ marginTop: 25 }}
                                            ></div>
                                            <div
                                                className="row"
                                                style={{ marginTop: 25 }}
                                            >
                                                <div className="col-12">
                                                    <h4>
                                                        Cliente:{" "}
                                                        {
                                                            responseProcess.nome_client
                                                        }
                                                    </h4>
                                                </div>
                                            </div>
                                            <section>
                                                <div
                                                    className="row"
                                                    style={{
                                                        marginTop: 13,
                                                    }}
                                                >
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="required">
                                                                Processo
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="processo"
                                                                placeholder="Número do Processo do Documento"
                                                                value={
                                                                    responseProcess.processo
                                                                }
                                                                onChange={
                                                                    allInputDescription
                                                                }
                                                                name="processo"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="required">
                                                                Valor Estimado
                                                                da Causa
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="valorEstimado"
                                                                value={
                                                                    responseProcess.valor_causa
                                                                }
                                                                class="form-control"
                                                                onChange={
                                                                    moneyInputChangeDescription
                                                                }
                                                                name="valor_causa"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="required">
                                                                Fórum
                                                            </label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <button
                                                                        class="btn btn-outline-success"
                                                                        type="button"
                                                                        data-toggle="modal"
                                                                        data-target="#forumModal"
                                                                    >
                                                                        Adicionar
                                                                    </button>
                                                                </div>
                                                                <select
                                                                    id="forum"
                                                                    name="forum"
                                                                    className="form-control"
                                                                >
                                                                    <option
                                                                        selected
                                                                    >
                                                                        {
                                                                            responseProcess.forum
                                                                        }
                                                                    </option>
                                                                    {responseForum.map(
                                                                        (
                                                                            res
                                                                        ) => (
                                                                            <option>
                                                                                {
                                                                                    res.descricao
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="required">
                                                                Data do Início
                                                                do Processo
                                                            </label>
                                                            <div
                                                                className="input-group date"
                                                                id="reservationdate"
                                                                data-target-input="nearest"
                                                            >
                                                                <input
                                                                    type="date"
                                                                    id="inicioProcesso"
                                                                    className="form-control datetimepicker-input"
                                                                    data-target="#reservationdate"
                                                                    name="data_inicio"
                                                                    value={
                                                                        responseProcess.data_inicio
                                                                    }
                                                                    onChange={
                                                                        allInputDescription
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="required">
                                                                Data Estimada do
                                                                Processo
                                                            </label>
                                                            <div
                                                                className="input-group date"
                                                                id="reservationdate"
                                                                data-target-input="nearest"
                                                            >
                                                                <input
                                                                    type="date"
                                                                    id="estimada"
                                                                    className="form-control datetimepicker-input"
                                                                    name="data_estimada"
                                                                    data-target="#reservationdate"
                                                                    value={
                                                                        responseProcess.data_estimada
                                                                    }
                                                                    onChange={
                                                                        allInputDescription
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="required">
                                                                Selecione uma
                                                                Ação
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                id="acao"
                                                                name="id_acao"
                                                            >
                                                                <option
                                                                    selected
                                                                >
                                                                    {
                                                                        responseAcao.nome
                                                                    }
                                                                </option>
                                                                <option>
                                                                    CIVIL
                                                                </option>
                                                                <option>
                                                                    PENAL
                                                                </option>
                                                                <option>
                                                                    TRABALHISTA
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="required">
                                                                Informe o Número
                                                                da Pasta
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="pasta"
                                                                name="pasta"
                                                                placeholder=""
                                                                value={
                                                                    responseProcess.pasta
                                                                }
                                                                onChange={
                                                                    allInputDescription
                                                                }
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Advogado (a) réu
                                                            </label>
                                                            <div
                                                                className="div-button-success text-white"
                                                                title="Adicionar Nova Pessoa"
                                                                data-toggle="modal"
                                                                data-target="#novaentidade"
                                                            >
                                                                <i
                                                                    className="fas fa-plus"
                                                                    style={{
                                                                        marginLeft:
                                                                            "5px",
                                                                    }}
                                                                ></i>
                                                                Adicionar Nova
                                                                Pessoa
                                                            </div>
                                                            <select
                                                                className="form-control select2 select2-hidden-accessible"
                                                                name="advogado"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                id="advogado"
                                                            >
                                                                <option selected="selected">
                                                                    {
                                                                        responseProcess.advogado
                                                                    }
                                                                </option>
                                                                {responsePessoa.map(
                                                                    (dados) => (
                                                                        <option>
                                                                            {
                                                                                dados.nome
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Réu</label>
                                                            <div
                                                                className="div-button-success text-white"
                                                                title="Adicionar Nova Pessoa"
                                                                data-toggle="modal"
                                                                data-target="#novaentidade"
                                                            >
                                                                <i
                                                                    className="fas fa-plus"
                                                                    style={{
                                                                        marginLeft:
                                                                            "5px",
                                                                    }}
                                                                ></i>
                                                                Adicionar Nova
                                                                Pessoa
                                                            </div>
                                                            <select
                                                                className="form-control select2 select2-hidden-accessible"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                name="reu"
                                                                id="reu"
                                                            >
                                                                <option selected="selected">
                                                                    {
                                                                        responseProcess.reu
                                                                    }
                                                                </option>
                                                                {responsePessoa.map(
                                                                    (dados) => (
                                                                        <option>
                                                                            {
                                                                                dados.nome
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Advogado (a)
                                                                autor
                                                            </label>
                                                            <div
                                                                className="div-button-success text-white"
                                                                title="Adicionar Nova Pessoa"
                                                                data-toggle="modal"
                                                                data-target="#novaentidade"
                                                            >
                                                                <i
                                                                    className="fas fa-plus"
                                                                    style={{
                                                                        marginLeft:
                                                                            "5px",
                                                                    }}
                                                                ></i>
                                                                Adicionar Nova
                                                                Pessoa
                                                            </div>
                                                            <select
                                                                className="form-control select2 select2-hidden-accessible"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                name="advAutor"
                                                                id="advAutor"
                                                            >
                                                                <option selected="selected">
                                                                    {
                                                                        responseProcess.advAutor
                                                                    }
                                                                </option>
                                                                {responsePessoa.map(
                                                                    (dados) => (
                                                                        <option>
                                                                            {
                                                                                dados.nome
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Autor</label>
                                                            <div
                                                                className="div-button-success text-white"
                                                                title="Adicionar Nova Pessoa"
                                                                data-toggle="modal"
                                                                data-target="#novaentidade"
                                                            >
                                                                <i
                                                                    className="fas fa-plus"
                                                                    style={{
                                                                        marginLeft:
                                                                            "5px",
                                                                    }}
                                                                ></i>
                                                                Adicionar Nova
                                                                Pessoa
                                                            </div>
                                                            <select
                                                                className="form-control select2 select2-hidden-accessible"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                name="autor"
                                                                id="autor"
                                                            >
                                                                <option selected="selected">
                                                                    {
                                                                        responseProcess.autor
                                                                    }
                                                                </option>
                                                                {responsePessoa.map(
                                                                    (dados) => (
                                                                        <option>
                                                                            {
                                                                                dados.nome
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>
                                                                Observação
                                                            </label>
                                                            <textarea
                                                                id="observacao"
                                                                class="form-control"
                                                                rows="6"
                                                                name="observacao"
                                                            >
                                                                {
                                                                    responseProcess.observacao
                                                                }
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="center-element">
                                                    <button
                                                        type="button"
                                                        class="btn btn-success"
                                                        onClick={updateProcess}
                                                    >
                                                        Atualizar
                                                    </button>
                                                </div>
                                            </section>
                                            <form
                                                action={`/api/observation/${id}`}
                                                method="post"
                                            >
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <i class="fa fa-comment"></i>
                                                        <label>
                                                            Comentários
                                                        </label>
                                                        <textarea
                                                            id="comentarios"
                                                            class="form-control"
                                                            name="observation"
                                                            rows="6"
                                                            placeholder="Deixe aqui seu comentário..."
                                                        ></textarea>
                                                    </div>
                                                    <div class="col-xs-12 text-center">
                                                        <button
                                                            class="btn btn-info"
                                                            id="comentar"
                                                            type="submit"
                                                        >
                                                            <i class="fa fa-comment"></i>
                                                            Comentar
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="segunda-aba"
                                            role="tabpanel"
                                        >
                                            <div className="card-body table-responsive p-0">
                                                <table class="table table-striped table-bordered mt-10">
                                                    <tbody>
                                                        <tr></tr>
                                                        <tr>
                                                            <th>
                                                                Número do
                                                                Processo
                                                            </th>
                                                            <th>
                                                                Data da Inserção
                                                                do Anexo
                                                            </th>
                                                            <th>
                                                                Nome do Anexo
                                                            </th>
                                                            <th>Referente</th>
                                                            <th>Ação</th>
                                                        </tr>

                                                        {responseAnexo.map(
                                                            (dados) => (
                                                                <tr>
                                                                    <th>
                                                                        {
                                                                            responseProcess.processo
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {dataFormato(
                                                                            dados.data
                                                                        )}
                                                                    </th>
                                                                    <th>
                                                                        <label title="Clique para visualizar o arquivo">
                                                                            <a
                                                                                style={{
                                                                                    color: "green",
                                                                                }}
                                                                                href={`https://guias.almeidaenogueira.com.br/api/download/${dados.id}`}
                                                                            >
                                                                                <i className="fa fa-cloud-download mr-3" />
                                                                                {
                                                                                    dados.nome
                                                                                }{" "}
                                                                            </a>
                                                                        </label>
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            dados.referente
                                                                        }
                                                                    </th>
                                                                    <th className="center-element">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                            title="Excluir"
                                                                            onClick={() =>
                                                                                deleteAnexo(
                                                                                    dados.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash" />
                                                                        </button>
                                                                    </th>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <form
                                                action={`/api/new/attachment/${responseParam}`}
                                                method="post"
                                                enctype="multipart/form-data"
                                            >
                                                <div
                                                    style={{
                                                        marginTop: 13,
                                                    }}
                                                    className="col-12"
                                                    id="divUp1"
                                                >
                                                    <div className="row">
                                                        <div className="col-7">
                                                            <h5>
                                                                Novos anexos
                                                            </h5>
                                                        </div>

                                                        <div className="col-4 form-group">
                                                            <input
                                                                name="upload"
                                                                id="upload"
                                                                type="file"
                                                                multiple
                                                                onChange={
                                                                    handleFileUpload
                                                                }
                                                            ></input>
                                                        </div>

                                                        <div className="col-1">
                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                id="botao"
                                                                onClick={() =>
                                                                    clickUpload()
                                                                }
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div id="novosAnexos"></div>
                                                    <div
                                                        className="row"
                                                        id="newAttach"
                                                    >
                                                        <div className="col-12 center-element">
                                                            <button
                                                                type="submit"
                                                                className="btn bg-success"
                                                            >
                                                                Salvar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr class="hr" />
                                                </div>
                                            </form>
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="terceira-aba"
                                            role="tabpanel"
                                        >
                                            <div
                                                className="col-12"
                                                id="autores-add"
                                            >
                                                <div className="row">
                                                    <div
                                                        className="col-lg-3 col-md-5 div-button-primary text-white"
                                                        title="Adicionar Réu Nesse Processo"
                                                        data-toggle="modal"
                                                        data-target="#novoreu"
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                        Adicionar Réu Nesse
                                                        Processo
                                                    </div>
                                                    <div
                                                        className="col-lg-3 col-md-5 div-button-success text-white"
                                                        title="Adicionar Nova Pessoa"
                                                        data-toggle="modal"
                                                        data-target="#novaentidade"
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                        Adicionar Nova Pessoa
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body table-responsive p-0">
                                                <table class="table table-striped table-bordered mt-10">
                                                    <tbody>
                                                        <tr></tr>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Telefone</th>
                                                            <th>CPF/CNPJ</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                        {responseReu.map(
                                                            (dados) => (
                                                                <tr>
                                                                    <th>
                                                                        {
                                                                            dados.nome
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            dados.telefone
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            dados.cpf_cnpj
                                                                        }
                                                                    </th>
                                                                    <th className="center-element">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                            title="Excluir"
                                                                            onClick={() =>
                                                                                deleteReu(
                                                                                    dados.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash" />
                                                                        </button>
                                                                    </th>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="quarta-aba"
                                            role="tabpanel"
                                        >
                                            <div
                                                className="col-12"
                                                id="autores-add"
                                            >
                                                <div className="row">
                                                    <div
                                                        className="col-xl-3 col-lg-5 col-md-5 div-button-primary text-white"
                                                        title="Adicionar Autor Nesse Processo"
                                                        data-toggle="modal"
                                                        data-target="#novoautor"
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                        Adicionar Autor Nesse
                                                        Processo
                                                    </div>
                                                    <div
                                                        className="col-xl-3 col-lg-4 col-md-5 div-button-success text-white"
                                                        title="Adicionar Nova Pessoa"
                                                        data-toggle="modal"
                                                        data-target="#novaentidade"
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                        Adicionar Nova Pessoa
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body table-responsive p-0">
                                                <table class="table table-striped table-bordered mt-10">
                                                    <tbody>
                                                        <tr></tr>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Telefone</th>
                                                            <th>CPF/CNPJ</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                        {responseAutor.map(
                                                            (dados) => (
                                                                <tr>
                                                                    <th>
                                                                        {
                                                                            dados.nome
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            dados.telefone
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            dados.cpf_cnpj
                                                                        }
                                                                    </th>
                                                                    <th className="center-element">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                            title="Excluir"
                                                                            onClick={() =>
                                                                                deleteActor(
                                                                                    dados.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash" />
                                                                        </button>
                                                                    </th>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="quinta-aba"
                                            role="tabpanel"
                                        >
                                            <div
                                                className="col-12"
                                                id="autores-add"
                                            >
                                                <div className="row">
                                                    <div
                                                        className="col-xl-3 col-lg-5 col-md-5 div-button-primary text-white"
                                                        title="Adicionar Advogado Nesse Processo"
                                                        data-toggle="modal"
                                                        data-target="#novoadvogado"
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                        Adicionar Advogado Nesse
                                                        Processo
                                                    </div>
                                                    <div
                                                        className="col-xl-3 col-lg-4 col-md-5 div-button-success text-white"
                                                        title="Adicionar Nova Pessoa"
                                                        data-toggle="modal"
                                                        data-target="#novaentidade"
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                        Adicionar Nova Pessoa
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body table-responsive p-0">
                                                <table class="table table-striped table-bordered mt-10">
                                                    <tbody>
                                                        <tr></tr>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Telefone</th>
                                                            <th>CPF/CNPJ</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                        {responseAdvogado.map(
                                                            (dados) => (
                                                                <tr>
                                                                    <th>
                                                                        {
                                                                            dados.nome
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            dados.telefone
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            dados.cpf_cnpj
                                                                        }
                                                                    </th>
                                                                    <th className="center-element">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                            title="Excluir"
                                                                            onClick={() =>
                                                                                deleteAdv(
                                                                                    dados.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash" />
                                                                        </button>
                                                                    </th>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="sexta-aba"
                                            role="tabpanel"
                                        >
                                            <div className="col-12">
                                                {testando()}
                                            </div>
                                            <div className="card-body table-responsive p-0">
                                                <table class="table table-bordered mt-10">
                                                    <thead>
                                                        <tr>
                                                            <th>Status</th>
                                                            <th>Download</th>
                                                            <th>Tipo</th>
                                                            <th>Descrição</th>
                                                            <th>Valor</th>
                                                            <th>
                                                                Data de
                                                                Pagamento
                                                            </th>
                                                            <th>Informações</th>
                                                            <th
                                                                style={{
                                                                    width: 130,
                                                                }}
                                                            >
                                                                Ação
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {responsePagamento.map(
                                                            (pagamento) => (
                                                                <tr>
                                                                    <td>
                                                                        <i
                                                                            class="fas fa-money-bill-wave bg-green center-element"
                                                                            title={`${pagamento.status}`}
                                                                            id="money"
                                                                        >
                                                                            {" "}
                                                                        </i>{" "}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            style={{
                                                                                color: "green",
                                                                            }}
                                                                            href={`https://guias.almeidaenogueira.com.br/api/download/pagamento/${pagamento.id}`}
                                                                        >
                                                                            <i className="fa fa-cloud-download mr-3" />
                                                                            {
                                                                                pagamento.anexo
                                                                            }{" "}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            pagamento.tipo
                                                                        }{" "}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            pagamento.descricao
                                                                        }{" "}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            pagamento.valor
                                                                        }{" "}
                                                                    </td>
                                                                    <td>
                                                                        {dataFormato(
                                                                            pagamento.data_pagamento
                                                                        )}{" "}
                                                                    </td>
                                                                    <td>
                                                                        {informacao(
                                                                            pagamento
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-info"
                                                                            type="button"
                                                                            data-toggle="modal"
                                                                            data-target="#updateModal"
                                                                            onClick={() =>
                                                                                edit(
                                                                                    pagamento.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fas fa-pen" />
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                            title="Excluir"
                                                                            onClick={() =>
                                                                                deletePagamento(
                                                                                    pagamento.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}

                                                        <tr>
                                                            <th>Total</th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th>{money()}</th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="setima-aba"
                                            role="tabpanel"
                                        >
                                            <div className="card-body table-responsive p-0">
                                                <table class="table table-striped table-bordered mt-10">
                                                    <tbody>
                                                        <tr></tr>
                                                        <tr>
                                                            <th>DATA</th>
                                                            <th>RESPONSÁVEL</th>
                                                            <th>STATUS</th>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                {responseWorkflow
                                                                    ? responseWorkflow.data
                                                                    : null}
                                                            </th>
                                                            <th>
                                                                {responseWorkflow
                                                                    ? responseWorkflow.responsavel
                                                                    : null}
                                                            </th>
                                                            <th>
                                                                {responseWorkflow
                                                                    ? responseWorkflow.status
                                                                    : null}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {verifyConfirmWorkflow()}
                                        </div>

                                        <div
                                            class="tab-pane fade"
                                            id="oitava-aba"
                                            role="tabpanel"
                                        >
                                            <div className="card-body table-responsive p-0">
                                                <table className="table table-striped table-bordered mt-10">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={
                                                                        handleSelectAll
                                                                    }
                                                                    checked={
                                                                        selectedDocs.length ===
                                                                        responseDocs.length
                                                                    }
                                                                />
                                                            </th>
                                                            <th>
                                                                Nome do Arquivo
                                                            </th>
                                                            <th>
                                                                Data da Inserção
                                                                do Arquivo
                                                            </th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {responseDocs.map(
                                                            (dados) => (
                                                                <tr
                                                                    key={
                                                                        dados.id
                                                                    }
                                                                >
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedDocs.includes(
                                                                                dados.id
                                                                            )}
                                                                            onChange={() =>
                                                                                handleSelectDoc(
                                                                                    dados.id
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            style={{
                                                                                color: "green",
                                                                            }}
                                                                            href={`https://guias.almeidaenogueira.com.br/api/download/documento/${dados.id}`}
                                                                        >
                                                                            {
                                                                                dados.nome
                                                                            }
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            dados.data
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            onClick={() =>
                                                                                deleteDocumento(
                                                                                    dados.id
                                                                                )
                                                                            }
                                                                            className="btn btn-danger"
                                                                            title="Excluir"
                                                                        >
                                                                            Excluir
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                                <div className="mt-3">
                                                    <button
                                                        className="btn btn-primary mr-2"
                                                        onClick={
                                                            downloadSelected
                                                        }
                                                        disabled={
                                                            selectedDocs.length ===
                                                                0 ||
                                                            isDownloading
                                                        }
                                                    >
                                                        {isDownloading
                                                            ? "Baixando..."
                                                            : "Baixar Selecionados"}
                                                    </button>
                                                    <button
                                                        className="btn btn-secondary"
                                                        onClick={downloadAll}
                                                        disabled={isDownloading}
                                                    >
                                                        {isDownloading
                                                            ? "Baixando..."
                                                            : "Baixar Todos"}
                                                    </button>
                                                </div>
                                            </div>
                                            <form
                                                action={`/api/novo/documento/${id}`}
                                                method="post"
                                                enctype="multipart/form-data"
                                            >
                                                <div
                                                    className="row"
                                                    id="pagamentoAnexo"
                                                    style={{
                                                        visibility: "visible",
                                                        display: "block",
                                                    }}
                                                >
                                                    <div className="col-12 center-element p-2">
                                                        <label>
                                                            Suba aqui seu
                                                            arquivo:
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 center-element p-2">
                                                        <div className="file-drop-area">
                                                            <span className="choose-file-button">
                                                                Escolha o
                                                                arquivo
                                                            </span>
                                                            <span className="file-message">
                                                                ou arraste e
                                                                solte ele aqui
                                                            </span>
                                                            <input
                                                                name="upload"
                                                                id="drop-file"
                                                                className="file-input"
                                                                type="file"
                                                                onChange={
                                                                    handleDocsUpload
                                                                }
                                                                multiple
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-12"
                                                    id="novoDocumento"
                                                ></div>
                                                <div className="row">
                                                    <div className="col-12 center-element p-2">
                                                        <button
                                                            type="submit"
                                                            class="btn btn-primary"
                                                        >
                                                            Salvar
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="box-header">
                                        <h4
                                            class="box-title"
                                            style={{ marginTop: 18 }}
                                        >
                                            <i class="fa fa-clock"></i>
                                            Histórico Geral
                                        </h4>
                                        <div
                                            className="container bootstrap snippets bootdeys center-element"
                                            style={{ marginTop: 30 }}
                                        >
                                            <div className="col-md-9">
                                                <div className="timeline-centered timeline-sm">
                                                    {responseTimeLine.map(
                                                        (dados, index) => (
                                                            <article
                                                                className={`timeline-entry ${
                                                                    index %
                                                                        2 ===
                                                                    0
                                                                        ? ""
                                                                        : "left-aligned"
                                                                }`}
                                                            >
                                                                <div className="timeline-entry-inner">
                                                                    <time className="timeline-time">
                                                                        <span>
                                                                            {
                                                                                dados.hora
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                dados.data
                                                                            }
                                                                        </span>
                                                                    </time>
                                                                    <div
                                                                        className={`timeline-icon ${dados.background}`}
                                                                    >
                                                                        <i
                                                                            className={`${dados.icon}`}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={`timeline-label ${dados.background}`}
                                                                    >
                                                                        <h4 className="timeline-title">
                                                                            {
                                                                                dados.usuario
                                                                            }
                                                                        </h4>
                                                                        <p>
                                                                            {
                                                                                dados.status
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div
                class="modal fade"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Gerar Novo Pagamento
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            {generatePayment()}

                            <label
                                className="required"
                                for="m-cadastrar-descricaoLbl"
                            >
                                Informe a data da primeira parcela
                            </label>
                            <div
                                className="input-group date"
                                id="reservationdate"
                                data-target-input="nearest"
                            >
                                <input
                                    type="date"
                                    id="data_pagamento_novo"
                                    className="form-control datetimepicker-input"
                                    data-target="#reservationdate"
                                    name="data"
                                />
                            </div>
                            <label
                                className="required"
                                for="m-cadastrar-descricaoLbl"
                            >
                                Informe o valor da parcela do pagamento R$
                            </label>
                            <input
                                type="text"
                                id="valor_novo"
                                value={value}
                                className="form-control"
                                onChange={handleInputChange}
                                name="valor"
                            />
                            <label for="m-cadastrar-descricaoLbl">
                                Informe a descricao
                            </label>
                            <textarea
                                class="form-control"
                                id="descricao_novo"
                                rows="3"
                                name="descricao"
                            ></textarea>
                            {controlAttachment()}
                            <div className="col-12" id="novoAnexo"></div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={novoPagamento}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="modal fade"
                id="forumModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Cadastrar Novo Fórum
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form method="post" action="/api/new/forum">
                            <div class="modal-body">
                                <label for="m-cadastrar-descricaoLbl">
                                    Descrição
                                </label>
                                <input
                                    type="text"
                                    id="m-cadastrar-descricao"
                                    className="form-control"
                                    name="descricao"
                                />
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Fechar
                                </button>
                                <button type="submit" class="btn btn-primary">
                                    Salvar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div
                class="modal fade"
                id="updateModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Editar Pagamento
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" id="editModal">
                            {startPayment()}

                            <label
                                className="required"
                                for="m-cadastrar-descricaoLbl"
                            >
                                Informe a data de pagamento da parcela
                            </label>
                            <div
                                className="input-group date"
                                id="reservationdate"
                                data-target-input="nearest"
                            >
                                <input
                                    id="data_pagamento"
                                    type="date"
                                    className="form-control datetimepicker-input"
                                    data-target="#reservationdate"
                                    name="data_pagamento"
                                    value={selectedPayment.data_pagamento}
                                    onChange={allInputChange}
                                />
                            </div>
                            <label
                                className="required"
                                for="m-cadastrar-descricaoLbl"
                            >
                                Informe o valor da parcela do pagamento R$
                            </label>
                            <input
                                id="valor"
                                type="text"
                                value={selectedPayment.valor}
                                className="form-control"
                                name="valor"
                                onChange={moneyInputChange}
                            />
                            <label for="m-cadastrar-descricaoLbl">
                                Informe a descricao
                            </label>
                            <textarea
                                id="descricao"
                                class="form-control"
                                rows="3"
                                name="descricao"
                                onChange={allInputChange}
                                value={selectedPayment.descricao}
                            />
                            <div
                                id="anexoDiv"
                                style={{
                                    visibility: "visible",
                                    display: "block",
                                }}
                            >
                                <label>Anexos:</label>
                                <label className="labelAnexo">
                                    {selectedPayment.anexo}
                                </label>
                                {selectedPayment.anexo ? (
                                    <>
                                        <span
                                            className="hidden-xs"
                                            title="Excluir"
                                            id="apagarAnexo"
                                            onClick={apagarAnexo}
                                        >
                                            <i
                                                className="fa fa-minus-circle ml-3 btn-remove-anexo text-danger"
                                                style={{
                                                    marginTop: 25,
                                                    fontSize: "1.25em",
                                                    position: "relative",
                                                    top: "0.15em",
                                                }}
                                            />
                                        </span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                            <input
                                id="anexoEdit"
                                class="form-control"
                                name="anexo"
                                type="text"
                                value={selectedPayment.anexo}
                                style={{
                                    visibility: "visible",
                                    display: "none",
                                }}
                            ></input>
                            <div
                                className="row"
                                style={{
                                    visibility: "visible",
                                    display: "block",
                                }}
                            >
                                <div className="col-10">
                                    <label for="m-cadastrar-descricaoLbl">
                                        Novos anexos
                                    </label>
                                </div>
                                <input
                                    name="upload"
                                    id="upload3"
                                    type="file"
                                    onChange={selectedAttachmentEditar}
                                ></input>
                                <div className="col-2">
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        title="Upload"
                                        onClick={() => clickUpload3()}
                                    >
                                        <i className="fas fa-file-upload"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12" id="novoAnexoEditar"></div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={updatePagamento}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="modal fade"
                id="novaentidade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Nova Pessoa
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label
                                className="required"
                                for="m-cadastrar-descricaoLbl"
                            >
                                Nome
                            </label>
                            <input
                                id="nome"
                                className="form-control"
                                name="nome"
                            ></input>
                            <label
                                className="required"
                                for="m-cadastrar-descricaoLbl"
                            >
                                Telefone
                            </label>
                            <input
                                id="telefone"
                                value={valueCel}
                                className="form-control"
                                name="telefone"
                                maxLength={14}
                                onChange={handleInputChangeCel}
                            ></input>
                            <label
                                className="required"
                                for="m-cadastrar-descricaoLbl"
                            >
                                CPF/CNPJ
                            </label>
                            <input
                                id="cpf_cnpj"
                                maxLength={18}
                                className="form-control"
                                name="cpf_cnpj"
                                value={valueCpf}
                                onChange={handleInputChangeCpf}
                            ></input>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={cadastrarPessoa}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="modal fade"
                id="novoautor"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Nova Autor
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label for="m-cadastrar-descricaoLbl">
                                Selecione a Pessoa
                            </label>
                            <select
                                className="form-control select2 select2-hidden-accessible"
                                style={{
                                    width: "100%",
                                    position: "static !important",
                                }}
                                name="autor"
                                id="nomeAutor"
                            >
                                {responsePessoa.map((dados) => (
                                    <option>{dados.nome}</option>
                                ))}
                            </select>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={cadastrarAutor}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="modal fade"
                id="novoreu"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Novo Réu
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label for="m-cadastrar-descricaoLbl">
                                Selecione o Réu
                            </label>
                            <select
                                id="nomeReu"
                                className="form-control select2 select2-hidden-accessible"
                                style={{
                                    width: "100%",
                                    position: "static !important",
                                }}
                                name="reu"
                            >
                                {responsePessoa.map((dados) => (
                                    <option>{dados.nome}</option>
                                ))}
                            </select>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={cadastrarReu}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="modal fade"
                id="novoadvogado"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Novo Advogado
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label for="m-cadastrar-descricaoLbl">
                                Selecione a Pessoa
                            </label>
                            <select
                                className="form-control select2 select2-hidden-accessible"
                                style={{ width: "100%" }}
                                name="adv"
                                id="nomeAdvogado"
                            >
                                {responsePessoa.map((dados) => (
                                    <option>{dados.nome}</option>
                                ))}
                            </select>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={cadastrarAdvogado}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Aners;
