import AppFooter from "../appFooter/appFooter";
import AppHeader from "../appHeader/appHeader";
import AppAdmin from "../appMenu/appAdmin";
import AppSetting from "../appSetting/appSetting";
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Clientes(){
    
    const token = Cookies.get('ANers');
    const [authenticated, setAuthenticated] = useState(false);
    const [resp, setResp] = useState(false);
    const [resC, setResC] = useState(false);
    const [editClient, setClient] = useState(null);

useEffect(() => {
  axios.post('/api/auth', null, {withCredentials: true})
    .then((response) => {
      if (response.data.status === 200) {
        setAuthenticated(true);
        setResp(response.data.user)
        setResC(response.data.cliente)
      } else {
        window.location = '/login';
      }
    })
    .catch(() => {
      window.location = '/login';
    });
}, []);

if (!authenticated) {
  return <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  }}>
<div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>;
}
    async function removeClient(id){
        try {
            const response = await axios.post(`/api/remove/client/${id}`,null,  {withCredentials: true});
            if(response.data.status==200){
                window.location = '/consultar/clientes'
            }
            else{
                alert(response.data.msg)
            }
        } catch (error) {
            alert(error)
            window.location = 'bad/request'
        }
        
    }

    async function save(id){
        try {
            let razao = document.querySelector('#razao').value
            if(razao==''||razao==null|| razao==undefined){
                return alert('Por favor preencha o campo "Razão social".')
            }
            const response = await axios.post(`/api/update/client/${id}`,{razao},  {withCredentials: true});
            if(response.data.status == 200){
                window.location = '/consultar/clientes'
            }
            else{
                alert(response.data.msg)
            }
        } catch (error) {
            alert(error)
            window.location = 'bad/request'
        }
    }

    function setIdClient(id){
        setClient(id)
    }

    return (
        <>
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>
         <div className="row center-element">
          <h1>
            Consultar Clientes
          </h1>
         </div>
         <div id='erros'>

         </div>
         <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
              <div class="row">
<div className="col-lg-12 col-md-12">
  <div className="white-box">

  <div className="tab-content">
  <table id="example1" className="table table-bordered table-striped dataTable dtr-inline" aria-describedby="example1_info">
          <thead>
            <tr>
            <th style={{width: 10}}>#</th>
              <th>Nome</th>
              <th style={{width: 100}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {
                resC.map((dados, index)=>(
                   <tr>
                   <td>{index+1}</td>
                    <td>{dados.nome}</td>
                    <td><button className="btn btn-danger" data-toggle="modal" data-target="#deleteClient" onClick={()=>setIdClient(dados.id)}><i className="fa fa-trash"></i></button>
                    <button style={{marginLeft: 10}} className="btn btn-info" data-toggle="modal" data-target="#editClient" onClick={()=>setIdClient(dados.id)}><i className="fa fa-edit"></i></button>
                    </td>
                  </tr>
                ))
              }
              
          </tbody>
        </table>
  </div>
  </div>
</div>

              </div>

              </div>
            </div>
          </div>
      </div>
      </div>
      </section>
<select className="select2" style={{display: 'none'}} />
<div class="modal fade" id="editClient" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
<div class="modal-header">
  <h5 class="modal-title" id="modalLabel">Deseja mesmo excluir este usuário?</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <label>Razão social</label>
    <input type="text" className="form-control" id="razao"/>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
  <button type="button" class="btn btn-primary" onClick={()=>save(editClient)}>Salvar</button>
</div>
</div>
</div>
</div>

       <div class="modal fade" id="deleteClient" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-sm" role="document">
<div class="modal-content">
<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Deseja mesmo excluir este cliente?</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div className="row">
      <div className="col-6 center-element">
	<button className="btn btn-danger" onClick={()=>removeClient(editClient)}>Sim</button>
      </div>
      <div className="col-6 center-element">
	    <button className="btn btn-primary">
	      Não
	    </button>
      </div>
      </div>
    </div>
</div>
</div>
</div>

        </>
    )
}

export default Clientes;
