import AppFooter from "../appFooter/appFooter";
import AppHeader from "../appHeader/appHeader";
import AppMenu from "../appMenu/appMenu";
import AppSetting from "../appSetting/appSetting";
import AppAdmin from "../appMenu/appAdmin";
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function CreateUser(){
  const token = Cookies.get('ANers');
  const [authenticated, setAuthenticated] = useState(false);
  const [resp, setResp] = useState(false);
  const [resC, setResC] = useState(false);
  const [valueCpf, setValueCpf] = useState('');
  const [phoneNumber , setPhoneNumber] = useState('');
  const [phoneNumber2 , setPhoneNumber2] = useState('');

useEffect(() => {
  axios.post('/api/auth', {token}, {WithCredentials: true})
    .then((response) => {
      if (response.data.status === 200) {
        setAuthenticated(true);
        setResp(response.data.user)
        setResC(response.data.cliente)
      } else {
        window.location = '/login';
      }
    })
    .catch(() => {
      window.location = '/login';
    });
}, []);

if (!authenticated) {
  return <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  }}>
<div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>;
}

function handleInputChangeCpf(event) {
     const { value } = event.target;

     // Remove tudo que não for número
     const newValue = value.replace(/\D/g, '');

     // Verifica se o valor tem 11 dígitos
     if (newValue.length === 11) {
       // Formata o CPF
       const formattedValue = newValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
       setValueCpf(formattedValue);
      } else if (newValue.length === 14) {
        // Formata o CNPJ
        const formattedValue = newValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        setValueCpf(formattedValue);
      } else {
        // Caso contrário, exibe o valor sem formatação
        setValueCpf(newValue);
      }

  }

const handlePhoneNumberChange = (event) => {
    const { value } = event.target;

    // Remove tudo que não for número
    const newValue = value.replace(/\D/g, '');

    // Formata o telefone
    let formattedValue = '';
    if (newValue.length <= 10) {
      // Formatação para telefone de 10 dígitos (XXXXXXXXXX)
      formattedValue = newValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else {
      // Formatação para telefone de 11 dígitos (XXXXXXXXXXX)
      formattedValue = newValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }

    setPhoneNumber(formattedValue);
  };

const handlePhoneNumberChange2 = (event) => {
    const { value } = event.target;

    // Remove tudo que não for número
    const newValue = value.replace(/\D/g, '');

    // Formata o telefone
    let formattedValue = '';
    if (newValue.length <= 10) {
      // Formatação para telefone de 10 dígitos (XXXXXXXXXX)
      formattedValue = newValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else {
      // Formatação para telefone de 11 dígitos (XXXXXXXXXXX)
      formattedValue = newValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }

    setPhoneNumber2(formattedValue);
  };

function cadastrarCliente(event){
  event.preventDefault();
  const nome = document.querySelector('#razao').value
  axios.post('/api/new/client', {nome},{withCredentials: true}).then((resp)=>{
    if(resp.data.status==200){
      //tudo deu certo, recarregar a página
      window.location = '/cadastrar/usuario'
    }
    else if(resp.data.status==400){
      //caso falte algum campo para preencher
      alert('Por favor digite um nome!')
    }else{
      //caso ocorra algum erro mandar para a página de 404
      alert(resp.data.status)
    }
  }).catch(error => alert('Algo de errado aconteceu! '+error))
}

function cadastrarUsuario(){
  const razao = document.querySelector('#nome').value
  const fantasia = document.querySelector('#fantasia').value
  const telefone = document.querySelector('#telefone1').value
  const telefone2 = document.querySelector('#telefone2').value
  const email = document.querySelector('#email').value
  const cliente = document.querySelector('#cliente').value
  const endereco = document.querySelector('#endereco').value
  const numero = document.querySelector('#numero').value
  const complemento = document.querySelector('#complemento').value
  const uf = document.querySelector('#uf').value
  const cidade = document.querySelector('#cidade').value
  const cep = document.querySelector('#cep').value
  const cpf_cnpj = document.querySelector('#cpf_cnpj').value
  const senha = document.querySelector('#senha').value
  const confirmarSenha = document.querySelector('#confirmar').value
  const divErros = document.querySelector('#erros')
      const excluir = divErros.querySelectorAll('.row')
      for(let cont=0; cont<excluir.length;cont++){
        excluir[cont].remove()
      }
  axios.post('/api/new/user', {razao, fantasia, telefone, telefone2, email, cliente, endereco, numero, complemento, uf , cidade, cep, cpf_cnpj, senha, confirmarSenha},{withCredentials: true}).then((resp)=>{
    if(resp.data.status==200){
      //tudo deu certo, recarregar a página
      window.location = '/cadastrar/usuario'
    }
    else if(resp.data.status==400){  
      //caso falte algum campo para preencher
      for(let i=0; i<resp.data.erros.length; i++){
        console.log(resp.data.erros[i].elemento)  
        let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
  
        elemento.style.backgroundColor = '#FFC0CB'
  
        let divRow = document.createElement('div') // Cria o elemento div
        divRow.className = 'row mt-3' // Adiciona uma margem superior de 3 unidades
        //divRow.style.backgroundColor = '#FFC0CB' // Define a cor de fundo da div
        divRow.style.padding = '10px' // Define um espaçamento interno de 10 pixels
  
        divRow.style.borderRadius = '5px' // Define um raio de borda de 5 pixels
  
        let divCol = document.createElement('div') // Cria o elemento div
        divCol.style.display = 'inline-block'
  
        divCol.style.backgroundColor = '#FFC0CB'
  
        let divText = document.createTextNode(`${resp.data.erros[i].mensagem}`) // Cria o elemento de texto
        divCol.appendChild(divText) // Adiciona o texto como filho de divCol
        divRow.appendChild(divCol)
        divErros.appendChild(divRow)
      }

    }else{
      alert(resp.data.msg)
    }
  }).catch((error)=>{
    console.log('errooooooooo2')  
    alert(error)
  })
}
    if(resp.id== 1){
      return (
	    <>
        
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>
         <div className="row center-element">
          <h1>
            Cadastrando Usuário
          </h1>
         </div>
         <div id='erros'>

         </div>
         <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
              <div class="row">
<div className="col-lg-12 col-md-12">
  <div className="white-box">

  <div className="tab-content">
             
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Nome de usuário</label>
                  <input type="text" name='nome' className="form-control" id="nome"/>
                  </div>
                </div>
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Nome Fantasia</label>
                  <input type="text" name='nome_fantasia' className="form-control" id="fantasia" />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Telefone 1</label>
                  <input type="text" className="form-control" maxLength={15} value={phoneNumber} onChange={handlePhoneNumberChange} name="telefone1" id="telefone1" />
                  </div>
                </div>
                <div className="col-6">
                <div className="form-group">
                  <label>Telefone 2</label>
                  <input type="text" className="form-control" maxLength={15} value={phoneNumber2} onChange={handlePhoneNumberChange2} name="telefone2" id="telefone2" />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Email</label>
                  <input type="email" className="form-control" name="email" id="email" placeholder="meuemail@email.com" />
                  </div>
                </div>
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Qual empresa pertence?</label>
                  <div className="input-group">
                  <div class="input-group-prepend">
                  <button class="btn btn-outline-success" type="button"  data-toggle="modal" data-target="#novoCliente">Adicionar</button>
                  </div>
                  <select className="form-control" id="cliente"><option></option>{resC.map(dados=>(<option>{dados.nome}</option>))}</select>
                  </div>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label >Endereço</label>
                  <input type="text" className="form-control" name="endereço" id="endereco" />
                  </div>
                </div>
                <div className="col-2">
                <div className="form-group">
                  <label >N°</label>
                  <input type="text" className="form-control" name="numero" id="numero" />
                  </div>
                </div>
                <div className="col-4">
                <div className="form-group">
                  <label>Complemento</label>
                  <input type="text" className="form-control" name='complemento' id="complemento" />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-2">
                <div className="form-group">
                  <label>U.F.</label>
                  <input type="text" className="form-control" name='uf' id="uf" />
                  </div>
                </div>
                <div className="col-2">
                <div className="form-group">
                  <label>Cidade</label>
                  <input type="text" className="form-control" name='cidade' id="cidade" />
                  </div>
                </div>
                <div className="col-4">
                <div className="form-group">
                  <label>CEP</label>
                  <input type="text" className="form-control" name='cep' id="cep" />
                  </div>
                </div>
                <div className="col-4">
                <div className="form-group">
                    <label className="required">
                      CPF/CNPJ
                    </label>
                    <input type="text" value={valueCpf} maxLength={18} className="form-control" id="cpf_cnpj" onChange={handleInputChangeCpf} />
                    </div>
                </div>
                </div>
                <div className='row'>
                  <div className="col-6">
                    <div className="form-group">
                    <label className="required">
                      Senha
                    </label>
                    <input type="password" className="form-control" id="senha" placeholder="******" />
                    </div>
                  </div>
                
                  <div className="col-6">
                    <div className="form-group">
                    <label className="required">
                      Confirmar Nova Senha
                    </label>
                    <input type="password" className="form-control" id="confirmar" placeholder="******" />
                    </div>
                  </div>
                  </div>
                <div className="col-md-12">
                
                <div class="col-xs-12 text-center">
                    <button class="btn btn-success" id="criar" onClick={cadastrarUsuario}>
                        Criar Perfil
                    </button>
                </div>
            </div>
  </div>
  </div>
</div>

              </div>

              </div>
            </div>
          </div>
      </div>
      </div>
      <div class="modal fade" id="novoCliente" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Cadastrar Novo Cliente</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form onSubmit={cadastrarCliente}>
<div class="modal-body">
    <label for="m-cadastrar-descricaoLbl">Razão social</label>
    <input type="text" id="razao" className="form-control" name="razao" />
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
  <button type='submit' class="btn btn-primary">Salvar</button>
</div>
</form>
</div>
</div>
</div>
      </section>
      
      </>       )
    }else{
	    window.location = '/bad/request'
    }
}

export default CreateUser

