import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppDashboard from '../appDashboard/appDashboard';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';



function CiCliente({response}) {

  const token = Cookies.get('ANers');
    const [authenticated, setAuthenticated] = useState(false);
    const [responseProcess, setResponse] = useState(false)
  
    useEffect(() => {
          console.log('=================')
          console.log(response)
          if (response.auth === true) {
            setAuthenticated(true);
            console.log(response.resp[0])
            console.log('===='+authenticated)
            setResponse(response.resp)
          } else {
            window.location = '/login';
          }
        },[authenticated])

  
    if (!authenticated) {
      return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>;
    }

    

  const Divider = styled.div`
  position: relative;
  height: 1px;
`;

const DivTransparent = styled(Divider)`
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
  }
`;

const DivArrowDown = styled(DivTransparent)`
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -6.5px;
    left: calc(25% - 7px);
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    background-color: white;
    border-bottom: 1px solid rgb(48,49,51);
    border-right: 1px solid rgb(48,49,51);
  }
`;

  return (
    <>
      
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>
       
         <div class="row">
          <div class="col-12">
            <div class="card">
            <div class="card-header bg-info">
            <div class="inner text-center"><img src="/dist/img/martelo.png" />
</div>  
          <h3 className="text-center">Controle de CI</h3>

          </div> 
              <div class="card-body">
                <div class="row">
                <div className="col-lg-6" style={{marginBottom: 10}}>

                <a href='/ci/controle'><div class="text-center">
                    <span><font color="#17a2b8">Consultar Processos</font></span>
                    </div></a>
                    </div>
                </div>
                <DivArrowDown />
                <div className="row">
  <div className="col-12">
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Consultar Processos</h3>
     </div> 
      <table id="example1"className="table table-bordered dataTable dtr-inline" aria-describedby="example1_info">
          <thead>
            <tr>
              <th>#</th>
              <th>Número do Processo</th>
              <th>Pasta do Processo</th>
              <th>Data Iniciada</th>
              <th>Observação</th>
              <th>Cliente</th>
	      <th>Adv/R</th>
	      <th>Réu</th>
	      <th>Adv/A</th>
	      <th>Autor</th>
            </tr>
          </thead>
          <tbody>
            {
              responseProcess.map(resp=> (
            <tr className='pointer' onClick={()=>{window.location=`/ci/pagamentos/${resp.id}`}}>
              <td>{resp.id}</td>
              <td>{resp.processo}</td>
              <td>{resp.pasta}</td>
              <td>{resp.data_inicio}</td>
              <td>{resp.observacao}</td>
              <td>{resp.nome_client}</td>
	      <td>{resp.advogado}</td>
	      <td>{resp.reu}</td>
	      <td>{resp.advAutor}</td>
	      <td>{resp.autor}</td>
            </tr>
              ))}
              
          </tbody>
        </table>
     
    </div>
  </div>
</div>

              </div>
            </div>
          </div>
      </div>
      </div>
      </section>  
    </>  

  );
}

export default CiCliente;

/*<div className="content-wrapper" style={{minHeight: 675}}>
      <div class="container-fluid">
      <div class="divider div-transparent div-arrow-down"></div>
      </div>
      </div>*/
