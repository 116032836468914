import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import AppHeader from "../appHeader/appHeader";
import AppMenu from "../appMenu/appMenu";
import AppFooter from "../appFooter/appFooter";
import AppSetting from "../appSetting/appSetting";
import AppAdmin from "../appMenu/appAdmin";
import { Box } from "@mui/material";
import Cookies from "js-cookie";

function Home() {
  const token = Cookies.get('ANers');
  const [authenticated, setAuthenticated] = useState(false);
  const [resp, setResp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

    const handleMenuClick = () => {
        setDrawerOpen(!drawerOpen); // Alterna o estado de drawerOpen
    };

  useEffect(() => {

    axios.post('/api/auth', {token}, {
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.status === 200) {
          setAuthenticated(true);
	  setResp(response.data.user);
        } else {
	  //window.alert(response.data.status)
          window.location = '/login';
        }
	setLoading(false);
      })
      .catch((err) => {
	//window.alert(err)
        window.location = '/login';
      });
  }, [token]);

  if (!authenticated) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>;
  }

	return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh", // Garantir que a altura mínima seja 100% da viewport
            }}
        >
            <AppHeader
                response={resp || { nome: "Carregando..." }}
                onMenuClick={handleMenuClick}
            />

            {/* Renderiza o menu lateral com base na autenticação */}
            {authenticated && resp && resp.id === 1 ? (
                <AppAdmin open={drawerOpen} setOpen={setDrawerOpen} />
            ) : (
                <AppMenu open={drawerOpen} setOpen={setDrawerOpen} />
            )}

            <Box
                sx={{
                    flexGrow: 1,
                }}
            >
                <Outlet /> {/* Conteúdo das rotas */}
            </Box>

            <AppFooter />
            <AppSetting />
        </Box>
    );
}

export default Home;
