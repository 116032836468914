import AppFooter from "../appFooter/appFooter";
import AppHeader from "../appHeader/appHeader";
import AppAdmin from "../appMenu/appAdmin";
import AppSetting from "../appSetting/appSetting";
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

function EditarUsuarios(){
    const token = Cookies.get('ANers');
    const [authenticated, setAuthenticated] = useState(false);
    const [resp, setResp] = useState(false);
    const [resC, setResC] = useState(false);
    const [user, setUser] = useState({nome: null, email: null, nome_fantasia: null, telefone1: null, telefone2: null, endereço: null, numero: null, complemento: null, uf: null, cidade: null, cep: null, cpf_cnpj: null})
    const [clientUser, setClientUser] = useState({nome: null})
    const {id} = useParams();
  
  useEffect(() => {
    axios.post('/api/auth', {id}, {withCredentials: true})
      .then((response) => {
        if (response.data.status === 200) {
          setAuthenticated(true);
          setResp(response.data.user) // obtem as informações do usuário administrador
          setResC(response.data.cliente) // obtem as informações dos clientes
          setUser(response.data.userInfo) // obtem as informações do usuário que está sendo editado
          setClientUser(response.data.clienteInfo) // obtem a informação do cliente do usuário que está sendo editado
        } else {
          window.location = '/login';
        }
      })
      .catch(() => {
        window.location = '/login';
      });
  }, []);
  
  if (!authenticated) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  </div>;
  }
  
  
  function editarUsuario(){
    let idUser = id
    let razao = document.querySelector('#nome').value
    let fantasia = document.querySelector('#fantasia').value
    let telefone = document.querySelector('#telefone1').value
    let telefone2 = document.querySelector('#telefone2').value
    let email = document.querySelector('#email').value
    let cliente = document.querySelector('#cliente').value
    let endereco = document.querySelector('#endereco').value
    let numero = document.querySelector('#numero').value
    let complemento = document.querySelector('#complemento').value
    let uf = document.querySelector('#uf').value
    let cidade = document.querySelector('#cidade').value
    let cep = document.querySelector('#cep').value
    let cpf_cnpj = document.querySelector('#cpf_cnpj').value
    let senha = document.querySelector('#senha').value
    let confirmarSenha = document.querySelector('#confirmar').value
    let divErros = document.querySelector('#erros')
    let excluir = divErros.querySelectorAll('.row')
        for(let cont=0; cont<excluir.length;cont++){
          excluir[cont].remove()
        }
    axios.post(`/api/update/user/${id}`, { idUser, razao, fantasia, telefone, telefone2, email, cliente, endereco, numero, complemento, uf , cidade, cep, cpf_cnpj, senha, confirmarSenha}, {withCrendentials: true}).then((resp)=>{
      if(resp.data.status==200){
        //tudo deu certo, recarregar a página
        window.location = '/consultar/usuarios'
      }
      else if(resp.data.status==400){  
        //caso falte algum campo para preencher
        for(let i=0; i<resp.data.erros.length; i++){
          console.log(resp.data.erros[i].elemento)  
          let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
    
          elemento.style.backgroundColor = '#FFC0CB'
    
          let divRow = document.createElement('div') // Cria o elemento div
          divRow.className = 'row mt-3' // Adiciona uma margem superior de 3 unidades
          //divRow.style.backgroundColor = '#FFC0CB' // Define a cor de fundo da div
          divRow.style.padding = '10px' // Define um espaçamento interno de 10 pixels
    
          divRow.style.borderRadius = '5px' // Define um raio de borda de 5 pixels
    
          let divCol = document.createElement('div') // Cria o elemento div
          divCol.style.display = 'inline-block'
    
          divCol.style.color = '#EF5350'
          divCol.style.fontWeight = 'bold'
    
          let divText = document.createTextNode(`${resp.data.erros[i].mensagem}`) // Cria o elemento de texto
          divCol.appendChild(divText) // Adiciona o texto como filho de divCol
          divRow.appendChild(divCol)
          divErros.appendChild(divRow)
        }
  
      }else{
        alert(resp.data.msg)
      }
    }).catch((error)=>{
      console.log('errooooooooo2')  
      alert(error)
    })
  }

  // função para formatar o campo de cpf/cnpj (cpf: 000.000.000-00) ou (cnpj: 00.000.000/0000-00)
  function formatCpf_Cnpj(e) {
    const campo = e.target;
    const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
  
    let novoValor = "";
  
    if (valor.length <= 11) {
      // CPF
      novoValor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      // CNPJ
      novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5");
    }
  
    campo.value = novoValor;
  }

  // função para formatar os campos de telefone de 10 ou 11 dígitos (telFixo: (00) 0000-0000) ou (celular: (00) 00000-0000)
  function formatTelefone(e) {
    const campo = e.target;
  const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito

  let novoValor = "";

  if (valor.length <= 10) {
    novoValor = valor.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  } else {
    novoValor = valor.replace(/(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
  }

  campo.value = novoValor;
  }

  // função para formatar o campo de cep (00000-000)
  function formatCep(e) {
    const campo = e.target;
    const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito

    let novoValor = "";

    if (valor.length <= 10) {
      novoValor = valor.replace(/(\d{5})(\d{3}).*/, "$1-$2");
    } 

    campo.value = novoValor;
  }
  
  // função responsável por atualizar o estado do componente com os novos valores dos campos de entrada do formulário
  const handleChange = (event) => {
    const { name, value } = event.target;
    // name representa o nome do campo de entrada 
    // value é o novo valor do campo.

    setUser((prevUser) => ({ ...prevUser, [name]: value })); 
    // atauliza a variável de estado user
    // altera apenas a propriedade que esta contida em name
    // mantem as demais propriedades da maneira como estavam ("...prevUser")
  };
  
      return (
          <>
        <section class= "content">
        <div className="container-fluid" style={{marginTop: 18}}>
           <div className="row center-element">
            <h1>
              Editando Usuário
            </h1>
           </div>
           <div id='erros'>
  
           </div>
           <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                <div class="row">
  <div className="col-lg-12 col-md-12">
    <div className="white-box">
  
    <div className="tab-content">
               
                  <div className="row">
                  <div className="col-6">
                  <div className="form-group">
                    <label className="required">Nome de usuário</label>
                    <input type="email" name='nome' className="form-control" id="nome" value={user.nome || ''} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-6">
                  <div className="form-group">
                    <label className="required">Nome Fantasia</label>
                    <input type="email" name='nome_fantasia' className="form-control" id="fantasia" value={user.nome_fantasia || ''} onChange={handleChange}/>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-6">
                  <div className="form-group">
                    <label className="required">Telefone 1</label>
                    <input type="email" className="form-control" name="telefone1" id="telefone1" value={user.telefone1 || ''} onChange={handleChange} onInput={formatTelefone}/>
                    </div>
                  </div>
                  <div className="col-6">
                  <div className="form-group">
                    <label>Telefone 2</label>
                    <input type="email" className="form-control" name="telefone2" id="telefone2" value={user.telefone2 || ''} onChange={handleChange} onInput={formatTelefone}/>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-6">
                  <div className="form-group">
                    <label className="required">Email</label>
                    <input type="email" className="form-control" name="email" id="email" value={user.email || ''} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-6">
                  <div className="form-group">
                    <label className="required">Qual empresa pertence?</label>
                    <div className="input-group">
                    <select className="form-control" id="cliente" name="cliente" value={clientUser.nome} disabled={true}><option></option>{resC.map(dados=>(<option>{dados.nome}</option>))}</select>
                    </div>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-6">
                  <div className="form-group">
                    <label >Endereço</label>
                    <input type="email" className="form-control" name="endereço" id="endereco" value={user.endereço || ''} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-2">
                  <div className="form-group">
                    <label >N°</label>
                    <input type="email" className="form-control" name="numero" id="numero" value={user.numero || ''} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-4">
                  <div className="form-group">
                    <label>Complemento</label>
                    <input type="email" className="form-control" name='complemento' id="complemento" value={user.complemento || ''} onChange={handleChange}/>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-2">
                  <div className="form-group">
                    <label>U.F.</label>
                      <div className="input-group">
                      <select className="form-control" id="uf" name="uf" value={user.uf} onChange={handleChange}>
                        <option>AC</option>
                        <option>AL</option>
                        <option>AP</option>
                        <option>AM</option>
                        <option>BA</option>
                        <option>CE</option>
                        <option>DF</option>
                        <option>ES</option>
                        <option>GO</option>
                        <option>MA</option>
                        <option>MT</option>
                        <option>MS</option>
                        <option>MG</option>
                        <option>PA</option>
                        <option>PB</option>
                        <option>PR</option>
                        <option>PE</option>
                        <option>PI</option>
                        <option>RJ</option>
                        <option>RN</option>
                        <option>RS</option>
                        <option>RO</option>
                        <option>RR</option>
                        <option>SC</option>
                        <option>SP</option>
                        <option>SE</option>
                        <option>TO</option>
                      </select>
                      </div>
                  </div>
                  </div>
                  <div className="col-2">
                  <div className="form-group">
                    <label>Cidade</label>
                    <input type="email" className="form-control" name='cidade' id="cidade" value={user.cidade || ''} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-4">
                  <div className="form-group">
                    <label>CEP</label>
                    <input type="email" className="form-control" name='cep' id="cep" value={user.cep || ''} onChange={handleChange} onInput={formatCep}/>
                    </div>
                  </div>
                  <div className="col-4">
                  <div className="form-group">
                      <label className="required">
                        CPF/CNPJ
                      </label>
                      <input type="text" className="form-control" id="cpf_cnpj" name="cpf_cnpj" onInput={formatCpf_Cnpj} value={user.cpf_cnpj} onChange={handleChange}/>

                      </div>
                  </div>
                  </div>
                  <div className='row'>
                    <div className="col-6">
                      <div className="form-group">
                      <label>
                       Nova Senha
                      </label>
                      <input type="password" className="form-control" id="senha" name="senha" />
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="form-group">
                      <label>
                        Confirmar Nova Senha
                      </label>
                      <input type="password" className="form-control" id="confirmar"/>
                      </div>
                    </div>
                    </div>
                  <div className="col-md-12">
                  
                  <div class="col-xs-12 text-center">
                    
                      <button class="btn btn-success" id="criar" onClick={()=>editarUsuario()}>
                          Salvar
                      </button>

                  </div>
              </div>
    </div>
    </div>
  </div>
  
                </div>
  
                </div>
              </div>
            </div>
        </div>
        </div>
        </section>
        </>  
      )
}

export default EditarUsuarios
