import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppDashboard from '../appDashboard/appDashboard';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbar,
} from "@mui/x-data-grid";
import { Button, Box, TextField } from "@mui/material";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";



function Ci({response}) {

  const token = Cookies.get('ANers');
    const [authenticated, setAuthenticated] = useState(false);
    const [responseProcess, setResponse] = useState([])
    const [searchText, setSearchText] = useState(""); // Estado para armazenar o valor da pesquisa
    const [filteredRows, setFilteredRows] = useState([]); // Estado para armazenar as linhas filtradas
  
    useEffect(() => {
      console.log('========')
      console.log(response)
      if (response.auth === true) {
        setAuthenticated(true);
        console.log(response.resp[0])
        console.log('===='+authenticated)
        setResponse(response.resp)
	setFilteredRows(response.resp); // Inicializa as linhas filtradas com todos os dados
      } else {
        window.location = '/login';
      }
    },[response])

    useEffect(() => {
        // Atualiza as linhas filtradas sempre que o texto de busca mudar
        setFilteredRows(
            responseProcess.filter((row) =>
                Object.values(row).some((value) =>
                    String(value)
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                )
            )
        );
    }, [searchText, responseProcess]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value); // Atualiza o texto de busca conforme o usuário digita
    };
  
    if (!authenticated) {
      return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>;
    }

    

  const Divider = styled.div`
  position: relative;
  height: 1px;
`;

const DivTransparent = styled(Divider)`
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
  }
`;

const DivArrowDown = styled(DivTransparent)`
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -6.5px;
    left: calc(25% - 7px);
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    background-color: white;
    border-bottom: 1px solid rgb(48,49,51);
    border-right: 1px solid rgb(48,49,51);
  }
`;
  async function removeProcess(id){
  try {
      const response = await axios.post(`/api/remove/process/${id}`,null,  {withCredentials: true});
      if(response.data.status==200){
          window.location = '/ci/controle'
      }
      else{
          alert(response.data.msg)
      }
  } catch (error) {
      alert(error)
      window.location = 'bad/request'
  }
  
}

const columns = [
        { field: "id", headerName: "#", width: 70 },
        {
            field: "processo",
            headerName: "Processo",
            width: 150,
            sortable: true,
            renderCell: (params) => (
                <div
                    onClick={() =>
                        (window.location = `/ci/pagamentos/${params.row.id}`)
                    }
                    style={{ cursor: "pointer", color: "#1a76d2" }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "pasta",
            headerName: "Pasta",
            width: 150,
            renderCell: (params) => (
                <div
                    onClick={() =>
                        (window.location = `/ci/pagamentos/${params.row.id}`)
                    }
                    style={{ cursor: "pointer", color: "#1a76d2" }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "data_inicio",
            headerName: "Início",
            width: 110,
            renderCell: (params) => (
                <div
                    onClick={() =>
                        (window.location = `/ci/pagamentos/${params.row.id}`)
                    }
                    style={{ cursor: "pointer", color: "#1a76d2" }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "nome_client",
            headerName: "Cliente",
            width: 150,
            renderCell: (params) => (
                <div
                    onClick={() =>
                        (window.location = `/ci/pagamentos/${params.row.id}`)
                    }
                    style={{ cursor: "pointer", color: "#1a76d2" }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "autor",
            headerName: "Autor",
            width: 150,
            renderCell: (params) => (
                <div
                    onClick={() =>
                        (window.location = `/ci/pagamentos/${params.row.id}`)
                    }
                    style={{ cursor: "pointer", color: "#1a76d2" }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "advAutor",
            headerName: "Adv/A",
            width: 150,
            renderCell: (params) => (
                <div
                    onClick={() =>
                        (window.location = `/ci/pagamentos/${params.row.id}`)
                    }
                    style={{ cursor: "pointer", color: "#1a76d2" }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "reu",
            headerName: "Réu",
            width: 150,
            renderCell: (params) => (
                <div
                    onClick={() =>
                        (window.location = `/ci/pagamentos/${params.row.id}`)
                    }
                    style={{ cursor: "pointer", color: "#1a76d2" }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "advogado",
            headerName: "Adv/R",
            width: 150,
            renderCell: (params) => (
                <div
                    onClick={() =>
                        (window.location = `/ci/pagamentos/${params.row.id}`)
                    }
                    style={{ cursor: "pointer", color: "#1a76d2" }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "acao",
            headerName: "Ação",
            width: 100,
            renderCell: (params) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <button
                        className="btn btn-danger"
                        onClick={() => removeProcess(params.row.id)}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            ),
        },
    ];

    const rows = responseProcess.map((resp) => ({
        id: resp.id,
        processo: resp.processo,
        pasta: resp.pasta,
        data_inicio: resp.data_inicio,
        nome_client: resp.nome_client,
        autor: resp.autor,
        advAutor: resp.advAutor,
        reu: resp.reu,
        advogado: resp.advogado,
    }));

    // Função para copiar a tabela inteira
    const handleCopyTable = () => {
        const csvData = rows.map((row) =>
            columns.map((col) => row[col.field]).join("\t")
        );
        const csvString = [columns.map((col) => col.headerName).join("\t")]
            .concat(csvData)
            .join("\n");
        navigator.clipboard.writeText(csvString).then(
            () => alert("Tabela copiada para a área de transferência!"),
            (err) => alert("Erro ao copiar a tabela: ", err)
        );
    };

    // Função para exportar para Excel
    const handleExportExcel = () => {
        // Criar um novo workbook e worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Processos");

        // Definir colunas
        const columnsExcel = columns
            .filter((col) => col.field !== "acao")
            .map((col) => ({
                header: col.headerName,
                key: col.field,
                width: 30, // Ajuste a largura conforme necessário
            }));

        worksheet.columns = columnsExcel;

        // Adicionar linhas
        rows.forEach((row) => {
            const rowData = {};
            columnsExcel.forEach((col) => {
                rowData[col.key] = row[col.key];
            });
            worksheet.addRow(rowData);
        });

        // Estilizar o cabeçalho
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF17A2B8" },
            };
            cell.alignment = {
                horizontal: "center",
                wrapText: true,
                vertical: "middle",
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });

        // Estilizar células de dados
        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
            if (rowNumber !== 1) {
                row.eachCell((cell) => {
                    cell.alignment = {
                        wrapText: true,
                        vertical: "center",
                        horizontal: "left",
                    };
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                });
            }
        });

        // Ajustar a altura das linhas para acomodar o wrapText
        worksheet.eachRow({ includeEmpty: false }, (row) => {
            row.height = 40; // Ajuste a altura conforme necessário
        });

        // Formatar datas, se necessário
        worksheet.getColumn("data_inicio").numFmt = "dd/mm/yyyy";

        // Salvar o arquivo
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            saveAs(blob, "processos.xlsx");
        });
    };

    // Função para exportar para PDF
    const handleExportPDF = () => {
        // Cria uma nova instância do jsPDF com orientação paisagem
        const doc = new jsPDF("landscape");

        // Define as colunas e seus títulos
        const columnsPDF = columns
            .filter((col) => col.field !== "acao")
            .map((col) => ({ header: col.headerName, dataKey: col.field }));

        // Prepara os dados das linhas
        const rowsPDF = rows.map((row) => {
            const rowData = {};
            columns.forEach((col) => {
                if (col.field !== "acao") {
                    rowData[col.field] = row[col.field]
                        ? row[col.field].toString()
                        : "";
                }
            });
            return rowData;
        });

        // Define as larguras das colunas (ajuste os valores conforme necessário)
        const columnStyles = {
            id: { cellWidth: 15 },
            processo: { cellWidth: 30 },
            pasta: { cellWidth: 30 },
            data_inicio: { cellWidth: 25 },
            nome_client: { cellWidth: 35 },
            autor: { cellWidth: 35 },
            advAutor: { cellWidth: 35 },
            reu: { cellWidth: 35 },
            advogado: { cellWidth: 35 },
        };

        // Gera a tabela no PDF
        doc.autoTable({
            columns: columnsPDF,
            body: rowsPDF,
            styles: {
                fontSize: 8,
                cellPadding: 2,
                overflow: "linebreak", // Habilita a quebra de linha
            },
            columnStyles: columnStyles,
            startY: 20, // Posição inicial da tabela no eixo Y
            margin: { top: 10, left: 10, right: 10, bottom: 10 },
            theme: "grid",
            headStyles: {
                fillColor: [22, 162, 184], // Cor de fundo do cabeçalho (mesma do 'bg-info')
                textColor: [255, 255, 255], // Cor do texto do cabeçalho
            },
        });

        // Salva o PDF
        doc.save("processos-novo.pdf");
    };

    // Função para imprimir a tabela
    const handlePrint = () => {
        const printContent = document.getElementById("data-grid");
        const WindowPrt = window.open("", "", "width=900,height=650");
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
    };

  return (
        <>
            <section className="content">
                <div className="container-fluid" style={{ marginTop: 18 }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-info">
                                    <div className="inner text-center">
                                        <img
                                            src="/dist/img/martelo.png"
                                            alt="Logo"
                                        />
                                    </div>
                                    <h3 className="text-center">
                                        Controle de CI
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div
                                            className="col-lg-6"
                                            style={{ marginBottom: 10 }}
                                            data-select2-id={30}
                                        >
                                            <Link to="/ci/controle">
                                                <div className="text-center">
                                                    <span>
                                                        <font color="#17a2b8">
                                                            Consultar Processos
                                                        </font>
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-lg-6">
                                            <Link to="/ci/novo/processo">
                                                <div className="text-center">
                                                    <span>
                                                        <font color="#212529">
                                                            Gerar Novo Processo
                                                        </font>
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <DivArrowDown />
                                    <div
                                        className="row"
                                        style={{ marginTop: 20 }}
                                    >
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">
                                                        Consultar Processos
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        mb={2}
                                                    >
                                                        {/* Central de Ações */}
                                                        <Box display="flex">
                                                            <Button
                                                                variant="contained"
                                                                color="#17a2b8"
                                                                onClick={
                                                                    handleCopyTable
                                                                }
                                                                style={{
                                                                    marginRight: 8,
                                                                }}
                                                            >
                                                                Copiar Tabela
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="#17a2b8"
                                                                onClick={
                                                                    handleExportExcel
                                                                }
                                                                style={{
                                                                    marginRight: 8,
                                                                }}
                                                            >
                                                                Exportar para
                                                                Excel
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="#17a2b8"
                                                                onClick={
                                                                    handleExportPDF
                                                                }
                                                            >
                                                                Exportar para
                                                                PDF
                                                            </Button>
                                                        </Box>

                                                        {/* Campo de Pesquisa */}
                                                        <TextField
                                                            label="Pesquisar"
                                                            variant="outlined"
                                                            value={searchText}
                                                            onChange={
                                                                handleSearchChange
                                                            }
                                                            style={{
                                                                flexGrow: 1,
                                                                maxWidth:
                                                                    "300px",
                                                            }} // Ajusta a largura conforme necessário
                                                        />
                                                    </Box>
                                                    {/* Tabela */}
                                                    <div
                                                        id="data-grid"
                                                        style={{
                                                            height: "100%",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <DataGrid
                                                            rows={filteredRows} // Filtra as linhas
                                                            columns={columns}
                                                            pageSize={10}
                                                            rowsPerPageOptions={[
                                                                10, 25, 50,
                                                            ]}
                                                            pagination
                                                            checkboxSelection={
                                                                false
                                                            }
                                                            disableSelectionOnClick
                                                            autoHeight
                                                            getRowHeight={() =>
                                                                "auto"
                                                            } // Ajuste automático da altura das linhas
                                                            sx={{
                                                                "& .MuiDataGrid-cell":
                                                                    {
                                                                        whiteSpace:
                                                                            "normal",
                                                                        lineHeight:
                                                                            "1.5",
                                                                        wordBreak:
                                                                            "break-word",
                                                                        overflow:
                                                                            "hidden",
                                                                    },
                                                                "& .MuiDataGrid-columnHeaders":
                                                                    {
                                                                        borderBottom:
                                                                            "2px solid #000",
                                                                    },
                                                                "& .MuiDataGrid-cell":
                                                                    {
                                                                        borderRight:
                                                                            "1px solid #ccc",
                                                                    },
                                                                "& .MuiDataGrid-cell:last-child":
                                                                    {
                                                                        borderRight:
                                                                            "none",
                                                                    },
                                                                "& .MuiDataGrid-row":
                                                                    {
                                                                        borderBottom:
                                                                            "1px solid #ccc",
                                                                    },
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Ci;
