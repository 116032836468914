import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppAdmin from '../appMenu/appAdmin';
import styled from 'styled-components';
import React, { useState, useEffect, createElement } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const Divider = styled.div`
  position: relative;
  height: 1px;
`;

const DivTransparent = styled(Divider)`
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
  }
`;

const DivArrowDown = styled(DivTransparent)`
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -6.5px;
    left: calc(75% - 7px);
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    background-color: white;
    border-bottom: 1px solid rgb(48,49,51);
    border-right: 1px solid rgb(48,49,51);
  }
`;

function GerarProcesso() {

  const token = Cookies.get('ANers');
  const { id } = useParams();
  const [authenticated, setAuthenticated] = useState(false);
  const [responseProcess, setResponse] = useState(false);
  const [responseForum, setResponseForum] = useState(false);
  const [responseCliente, setResponseCliente] = useState(false);
  const [responsePessoa, setResponsePessoa] = useState(false);
  const [user, setUser] = useState(false);
  const [valueCel, setValueCel] = useState('');
  const [valueCpf, setValueCpf] = useState('');

  // Estados para os campos do formulário
  const [processo, setProcesso] = useState('');
  const [valorEstimado, setValorEstimado] = useState('');
  const [forum, setForum] = useState('');
  const [cliente, setCliente] = useState('');
  const [inicioProcesso, setInicioProcesso] = useState('');
  const [estimada, setEstimada] = useState('');
  const [acao, setAcao] = useState('');
  const [pasta, setPasta] = useState('');
  const [autor, setAutor] = useState('');
  const [reu, setReu] = useState('');
  const [advogado, setAdvogado] = useState('');
  const [advAutor, setAdvAutor] = useState('');
  const [observacao, setObservacao] = useState('');
  const [descricaoForum, setDescricaoForum] = useState('');
  const [nomePessoa, setNomePessoa] = useState('');
  const [telefonePessoa, setTelefonePessoa] = useState('');
  const [cpfCnpjPessoa, setCpfCnpjPessoa] = useState('');

  useEffect(() => {
    axios.post('https://guias.almeidaenogueira.com.br/api/getgenerate', { token }, {
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.auth === true) {
          setAuthenticated(true);
          setResponse(response.data.resp);
          setResponseForum(response.data.resForum);
          setResponseCliente(response.data.resCliente);
          setUser(response.data.user);
          setResponsePessoa(response.data.resPessoa);

          // Carrega os dados do localStorage
          const savedFormData = JSON.parse(localStorage.getItem('formData'));
          if (savedFormData) {
            setProcesso(savedFormData.processo || '');
            setValorEstimado(savedFormData.valorEstimado || '');
            setForum(savedFormData.forum || '');
            setCliente(savedFormData.cliente || '');
            setInicioProcesso(savedFormData.inicioProcesso || '');
            setEstimada(savedFormData.estimada || '');
            setAcao(savedFormData.acao || '');
            setPasta(savedFormData.pasta || '');
            setAutor(savedFormData.autor || '');
            setReu(savedFormData.reu || '');
            setAdvogado(savedFormData.advogado || '');
            setAdvAutor(savedFormData.advAutor || '');
            setObservacao(savedFormData.observacao || '');
            setDescricaoForum(savedFormData.descricaoForum || '');
            setNomePessoa(savedFormData.nomePessoa || '');
            setTelefonePessoa(savedFormData.telefonePessoa || '');
            setCpfCnpjPessoa(savedFormData.cpfCnpjPessoa || '');
          }
        } else {
          window.location = '/login';
        }
      })
      .catch(() => {
        window.location = '/login';
      });
  }, []);

  const [value, setValue] = useState('');

  function handleInputChange(event) {
    const { value } = event.target;

    const newValue = value
      .replace(/\D/g, '') // Remove tudo que não for número
      .replace(/^0+/, '') // Remove os zeros iniciais
      .padStart(3, '0') // Adiciona zeros à esquerda, caso necessário
      .replace(/(\d{1,})(\d{2})$/, '$1,$2') // Adiciona a vírgula decimal
      .replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona o ponto como separador de milhares

    const formattedValue = `R$ ${newValue}`;
    setValorEstimado(formattedValue);
    saveFormData({ valorEstimado: formattedValue });
  }

  function saveFormData(updatedData) {
    const formData = {
      processo,
      valorEstimado,
      forum,
      cliente,
      inicioProcesso,
      estimada,
      acao,
      pasta,
      autor,
      reu,
      advogado,
      advAutor,
      observacao,
      descricaoForum,
      nomePessoa,
      telefonePessoa,
      cpfCnpjPessoa,
      ...updatedData, // Atualiza apenas o campo que mudou
    };
    localStorage.setItem('formData', JSON.stringify(formData));
  }

  function handleProcessoChange(event) {
    setProcesso(event.target.value);
    saveFormData({ processo: event.target.value });
  }

  function handleForumChange(event) {
    setForum(event.target.value);
    saveFormData({ forum: event.target.value });
  }

  function handleClienteChange(event) {
    setCliente(event.target.value);
    saveFormData({ cliente: event.target.value });
  }

  function handleInicioProcessoChange(event) {
    setInicioProcesso(event.target.value);
    saveFormData({ inicioProcesso: event.target.value });
  }

  function handleEstimadaChange(event) {
    setEstimada(event.target.value);
    saveFormData({ estimada: event.target.value });
  }

  function handleAcaoChange(event) {
    setAcao(event.target.value);
    saveFormData({ acao: event.target.value });
  }

  function handlePastaChange(event) {
    setPasta(event.target.value);
    saveFormData({ pasta: event.target.value });
  }

  function handleAutorChange(event) {
    setAutor(event.target.value);
    saveFormData({ autor: event.target.value });
  }

  function handleReuChange(event) {
    setReu(event.target.value);
    saveFormData({ reu: event.target.value });
  }

  function handleAdvogadoChange(event) {
    setAdvogado(event.target.value);
    saveFormData({ advogado: event.target.value });
  }

  function handleAdvAutorChange(event) {
    setAdvAutor(event.target.value);
    saveFormData({ advAutor: event.target.value });
  }

  function handleObservacaoChange(event) {
    setObservacao(event.target.value);
    saveFormData({ observacao: event.target.value });
  }

  function handleDescricaoForumChange(event) {
    setDescricaoForum(event.target.value);
    saveFormData({ descricaoForum: event.target.value });
  }

  function handleNomePessoaChange(event) {
    setNomePessoa(event.target.value);
    saveFormData({ nomePessoa: event.target.value });
  }

  function handleTelefonePessoaChange(event) {
    setTelefonePessoa(event.target.value);
    saveFormData({ telefonePessoa: event.target.value });
  }

  function handleCpfCnpjPessoaChange(event) {
    setCpfCnpjPessoa(event.target.value);
    saveFormData({ cpfCnpjPessoa: event.target.value });
  }

  function handleCancel() {
    localStorage.removeItem('formData');
    // Limpar estados se necessário
    setProcesso('');
    setValorEstimado('');
    setForum('');
    setCliente('');
    setInicioProcesso('');
    setEstimada('');
    setAcao('');
    setPasta('');
    setAutor('');
    setReu('');
    setAdvogado('');
    setAdvAutor('');
    setObservacao('');
    setDescricaoForum('');
    setNomePessoa('');
    setTelefonePessoa('');
    setCpfCnpjPessoa('');
    window.location = '/ci/controle';
  }

  if (!authenticated) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>;
  }

  function handleInputChangeCel(event) {

    let { value } = event.target;

    // Remove tudo que não for número
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
      // Limita o número de caracteres a 11
      value = value.slice(0, 11);
    }

    if (value.length === 11) {
      // Formata o número de celular
      const formattedValue = value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      setValueCel(formattedValue);
      handleTelefonePessoaChange({ target: { value: formattedValue } });
    } else {
      // Caso contrário, exibe o valor sem formatação
      setValueCel(value);
      handleTelefonePessoaChange({ target: { value } });
    }
  }

  function handleInputChangeCpf(event) {
    const { value } = event.target;
    // Remove tudo que não for número
    const newValue = value.replace(/\D/g, '');

    // Verifica se o valor tem 11 dígitos
    if (newValue.length === 11) {
      // Formata o CPF
      const formattedValue = newValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      setValueCpf(formattedValue);
      handleCpfCnpjPessoaChange({ target: { value: formattedValue } });
    } else if (newValue.length === 14) {
      // Formata o CNPJ
      const formattedValue = newValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      setValueCpf(formattedValue);
      handleCpfCnpjPessoaChange({ target: { value: formattedValue } });
    } else {
      // Caso contrário, exibe o valor sem formatação
      setValueCpf(newValue);
      handleCpfCnpjPessoaChange({ target: { value: newValue } });
    }

  }

  function cadastrarPessoa() {
    const nome = nomePessoa;
    const telefone = telefonePessoa;
    const cpf_cnpj = cpfCnpjPessoa;

    axios.post(`https://guias.almeidaenogueira.com.br/api/new/partner/${id}`, { token, nome, telefone, cpf_cnpj }, {
      withCredentials: true,
    }).then((resp) => {
      if (resp.data === 400) {
        alert("Essa pessoa/cpf já está cadastrado!");
      }
      if (resp.data === 404) {
        window.location = `/new/partner/erro`;
      }
      if (resp.data.erros.length === 0) {
        window.location.reload();
      } else {
        for (let i = 0; i < resp.data.erros.length; i++) {
          let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`);
          elemento.style.backgroundColor = '#FFC0CB';
        }
      }
    });
  }

  function setGenerate() {
    // Utilizando os estados em vez de document.querySelector
    const processoValue = processo;
    const valorEstimadoValue = valorEstimado;
    const forumValue = forum;
    const clienteValue = cliente;
    const inicioProcessoValue = inicioProcesso;
    const estimadaValue = estimada;
    const acaoValue = acao;
    const pastaValue = pasta;
    const autorValue = autor;
    const reuValue = reu;
    const advogadoValue = advogado;
    const advAutorValue = advAutor;
    const observacaoValue = observacao;

    const divErros = document.querySelector('#erros');
    const excluir = divErros.querySelectorAll('.row');
    for (let cont = 0; cont < excluir.length; cont++) {
      excluir[cont].remove();
    }

    axios.post('/api/generate/process', {
      token,
      processo: processoValue,
      valorEstimado: valorEstimadoValue,
      forum: forumValue,
      cliente: clienteValue,
      inicioProcesso: inicioProcessoValue,
      estimada: estimadaValue,
      acao: acaoValue,
      pasta: pastaValue,
      observacao: observacaoValue,
      reu: reuValue,
      autor: autorValue,
      advogado: advogadoValue,
      advAutor: advAutorValue,
    }, {
      withCredentials: true,
    }).then((resp) => {
      if (resp.data.erros.length === 0) {
        localStorage.removeItem('formData'); // Limpa o localStorage após o sucesso
        window.location = `/ci/pagamentos/${resp.data.id}`;
      } else {
	for (let i = 0; i < resp.data.erros.length; i++) {

          let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`);
          elemento.style.backgroundColor = '#FFC0CB';
          let divRow = document.createElement('div'); // Cria o elemento div
          divRow.className = 'row mt-3'; // Adiciona uma margem superior de 3 unidades
          //divRow.style.backgroundColor = '#FFC0CB'; // Define a cor de fundo da div
          divRow.style.padding = '10px'; // Define um espaçamento interno de 10 pixels
          divRow.style.borderRadius = '5px'; // Define um raio de borda de 5 pixels
          let divCol = document.createElement('div'); // Cria o elemento div
          divCol.style.display = 'inline-block';
          divCol.style.backgroundColor = '#FFC0CB';
          let divText = document.createTextNode(`${resp.data.erros[i].mensagem}`); // Cria o elemento de texto
          divCol.appendChild(divText); // Adiciona o texto como filho de divCol
          divRow.appendChild(divCol);
          divErros.appendChild(divRow);
        }

      }
    });
  }

  if (user.id == 1) {
    return (
      <>
          <section className="content">
            <div className="container-fluid" style={{ marginTop: 18 }}>

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header bg-info">
                      <div className="inner text-center"><img src="/dist/img/martelo.png" alt="Ícone" />
                      </div>
                      <h3 className="text-center">Controle de CI</h3>

                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6" style={{ marginBottom: 10 }}>
                          <Link to='/ci/controle'>
                            <div className="text-center">
                              <font color="#212529"><span>Consultar Processos</span></font>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-6">
                          <Link to='/ci/novo/processo'>
                            <div className="text-center">
                              <span><font color="#17a2b8">Gerar Novo Processo</font></span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <DivArrowDown />
                      <section>
                        <h5 style={{ marginTop: 15 }}>Gerar Novo Processo</h5>
                        <div id='erros'></div>
                        <div className="row" style={{ marginTop: 25 }}>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Processo</label>
                              <input type="text" className="form-control" id="processo" placeholder="Número do Processo do Documento" name='processo' value={processo} onChange={handleProcessoChange}></input>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Valor Estimado da Causa</label>
                              <input type="text" value={valorEstimado} className="form-control" id="valorEstimado" onChange={handleInputChange} name='valorEstimado'></input>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Fórum</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <button className="btn btn-outline-success" type="button" data-toggle="modal" data-target="#exampleModal">Adicionar</button>
                                </div>
                                <select className="form-control" id="forum" name='forum' value={forum} onChange={handleForumChange}>
                                  {
                                    responseForum && responseForum.map(res => (
                                      <option key={res.id} value={res.descricao}>{res.descricao}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Selecione o cliente</label>
                              <select className="form-control" id="cliente" name='cliente' value={cliente} onChange={handleClienteChange}>
                                {
                                  responseCliente && responseCliente.map(resp => (
                                    <option key={resp.id} value={resp.nome}>{resp.nome}</option>
                                  ))
                                }
                              </select>

                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Data do Início do Processo</label>
                              <div className="input-group date" data-target-input="nearest">
                                <input type="date" className="form-control" id="inicioProcesso" name='inicioProcesso' value={inicioProcesso} onChange={handleInicioProcessoChange} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Selecione uma Ação</label>
                              <select className="form-control" id="acao" name='acao' value={acao} onChange={handleAcaoChange}>
                                {
                                  responseProcess && responseProcess.map(resp => (
                                    <option key={resp.id} value={resp.nome}>{resp.nome}</option>
                                  ))
                                }
                              </select>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Informe o Número da Pasta</label>
                              <input type="text" className="form-control" id="pasta" placeholder="" name='pasta' value={pasta} onChange={handlePastaChange}></input>
                            </div>
                          </div>
                        </div>
                        {/* Campos adicionais para administrador */}
                        <div className="row">
  <div className="col-md-6">
    <div className="form-group">
      <label>Advogado (a) réu</label>
      <br />
      <div
        className="div-button-success text-white"
        title="Adicionar Nova Pessoa"
        data-toggle="modal"
        data-target="#novaentidade"
        style={{
          cursor: 'pointer',
          padding: '5px',
          backgroundColor: '#28a745',
          borderRadius: '5px',
          display: 'inline-block',
          marginBottom: '5px'
        }}
      >
        <i
          className="fas fa-plus"
          style={{ marginLeft: "5px" }}
        ></i>
        Adicionar Nova Pessoa
      </div>
      <select
        className="form-control"
        name="advogado"
        style={{ width: "100%" }}
        id="advogado"
        value={advogado}
        onChange={handleAdvogadoChange}
      >
        <option value="">Selecione</option>
        {responsePessoa && responsePessoa.map((dados) => (
          <option key={dados.id} value={dados.nome}>{dados.nome}</option>
        ))}
      </select>
    </div>
  </div>
  <div className="col-md-6">
    <div className="form-group">
      <label>Réu</label>
      <br />
      <div
        className="div-button-success text-white"
        title="Adicionar Nova Pessoa"
        data-toggle="modal"
        data-target="#novaentidade"
        style={{
          cursor: 'pointer',
          padding: '5px',
          backgroundColor: '#28a745',
          borderRadius: '5px',
          display: 'inline-block',
          marginBottom: '5px'
        }}
      >
        <i
          className="fas fa-plus"
          style={{ marginLeft: "5px" }}
        ></i>
        Adicionar Nova Pessoa
      </div>
      <select
        className="form-control"
        name="reu"
        style={{ width: "100%" }}
        id="reu"
        value={reu}
        onChange={handleReuChange}
      >
        <option value="">Selecione</option>
        {responsePessoa && responsePessoa.map((dados) => (
          <option key={dados.id} value={dados.nome}>{dados.nome}</option>
        ))}
      </select>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-md-6">
    <div className="form-group">
      <label>Advogado (a) autor</label>
      <br />
      <div
        className="div-button-success text-white"
        title="Adicionar Nova Pessoa"
        data-toggle="modal"
        data-target="#novaentidade"
        style={{
          cursor: 'pointer',
          padding: '5px',
          backgroundColor: '#28a745',
          borderRadius: '5px',
          display: 'inline-block',
          marginBottom: '5px'
        }}
      >
        <i
          className="fas fa-plus"
          style={{ marginLeft: "5px" }}
        ></i>
        Adicionar Nova Pessoa
      </div>
      <select
        className="form-control"
        name="advAutor"
        style={{ width: "100%" }}
        id="advAutor"
        value={advAutor}
        onChange={handleAdvAutorChange}
      >
        <option value="">Selecione</option>
        {responsePessoa && responsePessoa.map((dados) => (
          <option key={dados.id} value={dados.nome}>{dados.nome}</option>
        ))}
      </select>
    </div>
  </div>
  <div className="col-md-6">
    <div className="form-group">
      <label>Autor</label>
      <br />
      <div
        className="div-button-success text-white"
        title="Adicionar Nova Pessoa"
        data-toggle="modal"
        data-target="#novaentidade"
        style={{
          cursor: 'pointer',
          padding: '5px',
          backgroundColor: '#28a745',
          borderRadius: '5px',
          display: 'inline-block',
          marginBottom: '5px'
        }}
      >
        <i
          className="fas fa-plus"
          style={{ marginLeft: "5px" }}
        ></i>
        Adicionar Nova Pessoa
      </div>
      <select
        className="form-control"
        name="autor"
        style={{ width: "100%" }}
        id="autor"
        value={autor}
        onChange={handleAutorChange}
      >
        <option value="">Selecione</option>
        {responsePessoa && responsePessoa.map((dados) => (
          <option key={dados.id} value={dados.nome}>{dados.nome}</option>
        ))}
      </select>
    </div>
  </div>
</div>
{/* Fim dos campos adicionais */}
	    		<div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Observação</label>
                              <textarea className="form-control" id="observacao" rows="6" name='observacao' value={observacao} onChange={handleObservacaoChange}></textarea>
                            </div>
                          </div>
                        </div>
                        <button type='button' className='btn btn-success' onClick={setGenerate}>Salvar</button>
                        {' '}
                        <button type='button' className='btn btn-secondary' onClick={handleCancel}>Cancelar</button>

                      </section>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Modal para adicionar novo fórum */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Cadastrar Novo Fórum</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form method='post' action='/api/new/forum'>
                  <div className="modal-body">
                    <label htmlFor="m-cadastrar-descricaoLbl">Descrição</label>
                    <input type="text" id="m-cadastrar-descricao" className="form-control" name="descricao" value={descricaoForum} onChange={handleDescricaoForumChange} />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
                    <button type='submit' className="btn btn-primary">Salvar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Modal para adicionar nova pessoa */}
          <div className="modal fade" id="novaentidade" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Nova Pessoa</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <label className="required" htmlFor="nome">Nome</label>
                  <input id="nome" className='form-control' name='nome' value={nomePessoa} onChange={handleNomePessoaChange}></input>
                  <label className="required" htmlFor="telefone">Telefone</label>
                  <input id="telefone" value={valueCel} className='form-control' name="telefone" maxLength={14} onChange={handleInputChangeCel}></input>
                  <label  htmlFor="cpf_cnpj">CPF/CNPJ</label>
                  <input id="cpf_cnpj" maxLength={18} className='form-control' name="cpf_cnpj" value={valueCpf} onChange={handleInputChangeCpf}></input>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
                  <button type="button" className="btn btn-primary" onClick={cadastrarPessoa}>Salvar</button>
                </div>
              </div>
            </div>
          </div>

      </>

    );
  } else {

    return (
      <>
          <section className="content">
            <div className="container-fluid" style={{ marginTop: 18 }}>

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header bg-info">
                      <div className="inner text-center"><img src="/dist/img/martelo.png" alt="Ícone" />
                      </div>
                      <h3 className="text-center">Controle de CI</h3>

                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6" style={{ marginBottom: 10 }}>
                          <Link to='/ci/controle'>
                            <div className="text-center">
                              <font color="#212529"><span>Consultar Processos</span></font>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-6">
                          <Link to='/ci/novo/processo'>
                            <div className="text-center">
                              <span><font color="#17a2b8">Gerar Novo Processo</font></span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <DivArrowDown />
                      <section>
                        <h5 style={{ marginTop: 15 }}>Gerar Novo Processo</h5>
                        <div id='erros'></div>
                        <div className="row" style={{ marginTop: 25 }}>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Processo</label>
                              <input type="text" className="form-control" id="processo" placeholder="Número do Processo do Documento" name='processo' value={processo} onChange={handleProcessoChange}></input>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Valor Estimado da Causa</label>
                              <input type="text" value={valorEstimado} className="form-control" id="valorEstimado" onChange={handleInputChange} name='valorEstimado'></input>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Fórum</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <button className="btn btn-outline-success" type="button" data-toggle="modal" data-target="#exampleModal">Adicionar</button>
                                </div>
                                <select className="form-control" id="forum" name='forum' value={forum} onChange={handleForumChange}>
                                  {
                                    responseForum && responseForum.map(res => (
                                      <option key={res.id} value={res.descricao}>{res.descricao}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Selecione o cliente</label>
                              <select className="form-control" id="cliente" name='cliente' value={cliente} onChange={handleClienteChange}>
                                {
                                  responseCliente && responseCliente.map(resp => (
                                    <option key={resp.id} value={resp.nome}>{resp.nome}</option>
                                  ))
                                }
                              </select>
                             </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Data do Início do Processo</label>
                              <div className="input-group date" data-target-input="nearest">
                                <input type="date" className="form-control" id="inicioProcesso" name='inicioProcesso' value={inicioProcesso} onChange={handleInicioProcessoChange} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Prazo Estimado da Entrega</label>
                              <div className="input-group date" data-target-input="nearest">
                                <input type="date" className="form-control" id="estimada" name='estimada' value={estimada} onChange={handleEstimadaChange} />

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Selecione uma Ação</label>
                              <select className="form-control" id="acao" name='acao' value={acao} onChange={handleAcaoChange}>
                                {
                                  responseProcess && responseProcess.map(resp => (
                                    <option key={resp.id} value={resp.nome}>{resp.nome}</option>
                                  ))
                                }
                              </select>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="required">Informe o Número da Pasta</label>
                              <input type="text" className="form-control" id="pasta" placeholder="" name='pasta' value={pasta} onChange={handlePastaChange}></input>
                            </div>
                          </div>
                        </div>
                        {/* Campos específicos para usuários não administradores */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Informe o advogado (a) do autor</label>
                              <input
                                type="text"
                                className="form-control"
                                id="advAutor"
                                placeholder=""
                                name="advAutor"
                                value={advAutor}
                                onChange={handleAdvAutorChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Informe Autor</label>
                              <input
                                type="text"
                                className="form-control"
                                id="autor"
                                placeholder=""
                                name="autor"
                                value={autor}
                                onChange={handleAutorChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Informe o advogado (a) do réu</label>
                              <input
                                type="text"
                                className="form-control"
                                id="advogado"
                                placeholder=""
                                name="advogado"
                                value={advogado}
                                onChange={handleAdvogadoChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Informe Réu</label>
                              <input
                                type="text"
                                className="form-control"
                                id="reu"
                                placeholder=""
                                name="reu"
                                value={reu}
                                onChange={handleReuChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Fim dos campos específicos */}
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Observação</label>
                              <textarea className="form-control" id="observacao" rows="6" name='observacao' value={observacao} onChange={handleObservacaoChange}></textarea>
                            </div>
                          </div>
                        </div>
                        <button type='button' className='btn btn-success' onClick={setGenerate}>Salvar</button>
                        {' '}
                        <button type='button' className='btn btn-secondary' onClick={handleCancel}>Cancelar</button>

                      </section>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Modal para adicionar novo fórum */}
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cadastrar Novo Fórum</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
	 </button>
      </div>
      <form method='post' action='/api/new/forum'>
      <div class="modal-body">
          <label for="m-cadastrar-descricaoLbl">Descrição</label>
          <input type="text" id="m-cadastrar-descricao" className="form-control" name="descricao" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        <button type='submit' class="btn btn-primary">Salvar</button>
      </div>
      </form>
    </div>
  </div>
</div>
      </>

    );
  }
}

export default GerarProcesso;

/*<div className="content-wrapper" style={{minHeight: 675}}>
      <div className="container-fluid">
      <div className="divider div-transparent div-arrow-down"></div>
      </div>
      </div>*/
